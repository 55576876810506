export function buildTree(data) {
  let tree = [];
  let lookup = {};

  // Create a lookup table for quick access
  data.forEach((item) => {
    lookup[item.Kodi] = {
      key: item.Kodi.toString(),
      data: {
        Kodi: item.Kodi,
        Pershkrim: item.Pershkrim,
        // Origjina: item.Origjina || null,
        Tarifa_Kodi: item.Tarifa_Kodi || "", // Handle undefined values
        AP: item.AP || "", // Handle undefined values
        Veprime: item.Veprime === 1, // Checkbox default state
      },
      children: [],
    };
  });

  // Build the tree structure
  data.forEach((item) => {
    if (item.Origjina === null) {
      // If the item doesn't have a parent (Origjina is null), it becomes a root node
      tree.push(lookup[item.Kodi]);
    } else {
      // If the item has a parent, try to add it to the parent's children
      if (lookup[item.Origjina]) {
        lookup[item.Origjina].children.push(lookup[item.Kodi]);
      } else {
        // If the parent doesn't exist, treat this item as a root node
        tree.push(lookup[item.Kodi]);
      }
    }
  });

  return tree;
}
