const columns = [
  { field: "Tipi", title: "Tipi", maxWidth: "50px" },
  { field: "NRLL", title: "Llogari", maxWidth: "70px" },
  { field: "MON", title: "Monedha", maxWidth: "50px" },
  { field: "KLFU_KODI", title: "Kodi", maxWidth: "150px" },
  { field: "KLFU_PERSHKRIM", title: "Pershkrim", maxWidth: "150px" },
  { field: "Kursi", title: "Kursi", maxWidth: "150px" },
  { field: "Kursi_Sot", title: "Kursi Sot", maxWidth: "150px" },

  { field: "VLDEBI_TOTAL", title: "Debi", maxWidth: "150px" },
  { field: "VLKREDI_TOTAL", title: "Kredi", maxWidth: "150px" },
  { field: "VLGJENDJE_TOTAL", title: "Gjendja", maxWidth: "150px" },
  { field: "Gjendja_Sot", title: "Gjendja Sot", maxWidth: "150px" },
  { field: "GJENDJE", title: "Gjendja Mon", maxWidth: "150px" },

  { field: "Fitim", title: "FITIM", maxWidth: "150px" },
  { field: "Humbje", title: "HUMBJE", maxWidth: "150px" },
  { field: "Diferenca", title: "Diferenca", maxWidth: "150px" },
];

export { columns };
