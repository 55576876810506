import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
} from "@mui/material";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import CloseIcon from "@mui/icons-material/Close";

const DitariBtn = ({ pdfUrl }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ marginLeft: "10px" }}>
      <Tooltip title="Ditari Dokumentit" arrow>
        <Button
          startIcon={<HistoryEduIcon />}
          onClick={handleOpen}
          variant="outlined"
        />
      </Tooltip>

      {/* Dialog for PDF */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", right: 10, top: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <iframe
            src={pdfUrl}
            width="100%"
            height="600px"
            style={{ border: "none" }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DitariBtn;
