// LlogariHandler.js
import { useDispatch } from "react-redux";
import mainAxios from "../../../../services/axios";
import { getLlogari } from "../../../../store/globalSlice";
import { useToast } from "../../../../components/context/ToastContext";

const LlogariHandler = ({
  state,
  handleRowClick,
  setDisabled,
  setButtonClicked,
  showLoading,
  hideLoading,
}) => {
  const dispatch = useDispatch();
  const showToast = useToast();
  const handleAddLlogari = async () => {
    try {
      showLoading();
      const response = await mainAxios.post("/llogari", {
        Kodi: state.Kodi,
        Pershkrim: state.Pershkrim,
        AP: state.AP,
        Tarifa_Kodi: state.Tarifa_Kodi,
        Pershkrim_Kredi: state.Pershkrim_Kredi,
        Pershkrim_Debi: state.Pershkrim_Debi,
        Origjina: state.Origjina,
        Veprime: state.Veprime,
        ImageIndex: state.ImageIndex,
        Aktiv: state.Aktiv,
        Selektuar: state.Selektuar,
        Rendit: state.Rendit,
        Shitje_V: state.Shitje_V,
        Blerje_V: state.Blerje_V,
        Arka_MA_V: state.Arka_MA_V,
        Arka_MP_V: state.Arka_MP_V,
        Banka_MA_V: state.Banka_MA_V,
        Banka_MP_V: state.Banka_MP_V,
        Klient_V: state.Klient_V,
        Furnitor_V: state.Furnitor_V,
        KaVeprime: state.KaVeprime,
        Shqip: state.Shqip,
        Anglisht: state.Anglisht,
        Gjermanisht: state.Gjermanisht,
        Maqedonisht: state.Maqedonisht,
        Italisht: state.Italisht,
        Turqisht: state.Turqisht,
        Kroatisht: state.Kroatisht,
        Spanjisht: state.Spanjisht,
        Arabisht: state.Arabisht,
        Frengjisht: state.Frengjisht,
        Rusisht: state.Rusisht,
        Bullgarisht: state.Bullgarisht,
        Shenim1: state.Shenim1,
        Shenim2: state.Shenim2,
        TipVlereBlerje_ID: state.TipVlereBlerje_ID,
        TipVlereShitje_ID: state.TipVlereShitje_ID,
        TipVlereImport_ID: state.TipVlereImport_ID,
        Llogari_Kls01_ID: state.Llogari_Kls01_ID,
        Llogari_Kls02_ID: state.Llogari_Kls02_ID,
        Llogari_Kls03_ID: state.Llogari_Kls03_ID,
        Llogari_Kls04_ID: state.Llogari_Kls04_ID,
        Llogari_Kls05_ID: state.Llogari_Kls05_ID,
        NukNdryshonVlerat: state.NukNdryshonVlerat,
        NotForSync: state.NotForSync,
      });
      if (response.status === 201) {
        handleRowClick(response.data);
        setDisabled(true);
        setButtonClicked(false);

        dispatch(getLlogari(`/llogari/search`));

        showToast("Llogaria u rregjistrua.", {
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);

      showToast("Gabim ne regjistrimin e llogarise.", {
        severity: "error",
      });
    } finally {
      hideLoading();
    }
  };

  return {
    handleAddLlogari,
  };
};

export default LlogariHandler;
