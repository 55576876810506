// HeaderMobile.js
import React from "react";
import Button from "@mui/material/Button";
import { MultiSelect } from "primereact/multiselect";
import { TextField } from "@mui/material";
import ManageSearchRoundedIcon from "@mui/icons-material/ManageSearchRounded";
import Buttons from "../../EInvoice/Buttons/Buttons";

const HeaderMobile = ({
  dataNga,
  setDataNga,
  dataDeri,
  setDataDeri,
  performSearch,
  selectedColumns,
  columns,
  data,
  setData,
  fromrivlersimi,
  onColumnToggle,
  fromEInvoice,
  disabledData,
  onTestiChange,
  selectedProduct,
}) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}
      >
        <Button
          style={{ height: "100%" }}
          variant="contained"
          disabled={!dataNga || !dataDeri}
          onClick={(e) => {
            e.preventDefault();
            performSearch();
          }}
        >
          <ManageSearchRoundedIcon />
        </Button>
        {fromEInvoice ? (
          // <Buttons
          //   disabledData={disabledData}
          //   onTestiChange={onTestiChange}
          //   selectedProduct={selectedProduct}
          // />
          <></>
        ) : (
          <MultiSelect
            style={{ height: "100%", width: "3em" }}
            // className="selectFature"
            value={selectedColumns}
            options={columns}
            optionLabel="title"
            onChange={onColumnToggle}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        {fromrivlersimi ? (
          <TextField
            style={{ height: "100%", marginBottom: "5px", width: "100%" }}
            type="date"
            label="Data"
            InputLabelProps={{ shrink: true }}
            value={data}
            onChange={(e) => setData(e.target.value)}
          />
        ) : (
          <>
            <TextField
              style={{ height: "100%", marginBottom: "5px", width: "100%" }}
              type="date"
              label="Nga Data"
              InputLabelProps={{ shrink: true }}
              value={dataNga}
              onChange={(e) => setDataNga(e.target.value)}
            />
            <TextField
              style={{ height: "100%", width: "100%" }}
              type="date"
              label="Deri Date"
              InputLabelProps={{ shrink: true }}
              value={dataDeri}
              onChange={(e) => setDataDeri(e.target.value)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderMobile;
