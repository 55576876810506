import mainAxios from "../../../services/axios";
import { formatDate } from "./formatDate";

// Utility to map row data efficiently
const getItemsForSecondRequest = (rows, newEntryID, fromModify) =>
  rows.map((row) => ({
    ...(fromModify ? { Id: row.Id } : {}),
    Master_Id: newEntryID,
    Pershkrim: row.Pershkrim,
    Vlera: row.Vlera,
    Sasia: row.Sasia,
    Tipi: row.Tipi,
    SkontoAgjent: row.SkontoAgjent,
    Cmimi: row.Cmimi,
    Cmimi_Pa_Tvsh: row.Cmimi_Pa_Tvsh,
    Operator: row.Operator,
    Vlera_Pa_Tvsh: row.Vlera_Pa_Tvsh,
    Kodi: row.Kodi,
    KLFU_Kodi: row.KLFU_Kodi,
    Tvsh: row.Tvsh,
    DateGarancie: row.DateGarancie,
    Rimbursim_Cmimi: row.Rimbursim_Cmimi,
    NrLL: row.NrLL,
    Shenim2: row.Shenim2,
    IdOld: row.IdOld,
    Skonto_VleraAgjent: row.Skonto_VleraAgjent,
    Art_ID: row.Art_ID,
    Punonjes_Id: row.Punonjes_Id,
    BarKod: row.BarKod,
    Njesi_Kodi: row.Njesi_Kodi,
    Skonto_Vlera: row.Skonto_Vlera,
    Total: row.Total,
    Magazina_Kodi: row.Magazina_Kodi,
    TipVlere_ID: row.TipVlere_ID,
    Tvsh_Vlera: row.Tvsh_Vlera,
    NrSerik: row.NrSerik,
    SasiaPak: row.SasiaPak,
    Cmimi_Kosto: row.Cmimi_Kosto,
    Skonto: row.Skonto,
  }));

// Handle the add action with async requests and optimized logic
const handleAdd = async (
  urlKey,
  showLoading,
  state,
  username,
  setState,
  defaultState,
  setDisabled,
  setButtonClicked,
  setSearchString,
  showToast,
  rows,
  tipiDTL,
  tipiArkes,
  fetchFShitje,
  setCurrentAction,
  hideLoading
) => {
  const fromModify = false;
  try {
    showLoading();

    // Prepare data once for both requests
    const requestData = {
      ...state,
      Data_Deklarimit: formatDate(state.Data_Deklarimit, true),
      Data: formatDate(state.Data, true),
      Afati_PagesesData: formatDate(state.Afati_PagesesData, true),
    };

    // Execute POST request for Fature Shitje
    const response = await mainAxios.post(`${urlKey}`, requestData);

    if (response.status === 200) {
      setState(defaultState);
      setDisabled(true);
      setButtonClicked(false);
      setSearchString("");

      const newEntryID = response.data.Id;
      showToast("Fatura u rregjistrua.", { severity: "success" });

      // Process second request for DTL
      const dtlDataArray = getItemsForSecondRequest(
        rows,
        newEntryID,
        fromModify
      );
      // First request (PUT request)
      const responseDtl = await mainAxios.post(
        `/fature/shitje/dtl/bulk`,
        dtlDataArray
      );

      if (responseDtl.status === 200) {
        // Second request (POST request) only if the first one is successful
        const responseFD = await mainAxios.post(
          `/fature/shitje/f_insert/${newEntryID}`
        );

        if (responseFD.status === 200 && tipiDTL === "AR") {
          if (state.Arka_Banka_Kodi) {
            const arkaRequest =
              tipiArkes === "ARKA"
                ? mainAxios.post(`/ftsh/mandat/${newEntryID}`)
                : mainAxios.post(`/ftsh/mandat/banka/${newEntryID}`);
            await arkaRequest;
          }

          fetchFShitje(1);
          setCurrentAction(null);
          showToast("Flete Dalja u gjenerua.", { severity: "success" });
        } else {
          showToast("Nuk u gjenerua flete dalje.", { severity: "error" });
        }
      } else {
        showToast("Gabim gjatë regjistrimit të detajeve", {
          severity: "error",
        });
      }
    } else {
      showToast("Gabim gjatë regjistrimit të artikujve", { severity: "error" });
    }
  } catch (error) {
    console.error("Error in POST request:", error);
    showToast("Gabim gjatë regjistrimit të faturës", { severity: "error" });
  } finally {
    hideLoading();
  }
};
let hasArObject;

// Kur Klikohet butoni i "MODIFIKO"
const handleModify = async (
  urlKey,
  showLoading,
  state,
  username,
  setState,
  defaultState,
  setDisabled,
  setButtonClicked,
  setSearchString,
  showToast,
  rows,
  tipiDTL,
  tipiArkes,
  fetchFShitje,
  setCurrentAction,
  hideLoading,
  number,
  currentId
) => {
  const fromModify = true;
  try {
    showLoading();
    const response = await mainAxios.put(`${urlKey}/${currentId}`, {
      Kodi_Org: state.Kodi_Org,
      Tip_Cmimi: state.Tip_Cmimi,
      Flete_Dalje_KODI: Number(state.Kodi),
      Menyra_Pageses_ID: Number(state.Menyra_Pageses_ID),
      NrSerik: state.NrSerik,
      Veprimi: state.Veprimi,
      Shenim: state.Shenim,
      Kodi: Number(state.Kodi),
      Eksport: Number(state.Eksport),
      Magazina_Kodi: state.Magazina_Kodi,
      NIPT: state.NIPT,
      Afati_PagesesDite: Number(state.Afati_PagesesDite),
      Data_Deklarimit: formatDate(state.Data_Deklarimit, true),
      Data: formatDate(state.Data, true),
      Mon: state.Mon,
      Kursi: Number(state.Kursi),
      Dep_Kodi: state.Dep_Kodi,
      KLFU_Kodi: state.KLFU_Kodi,
      Paguar: Number(state.Paguar),
      Arka_Banka_Kodi: String(state.Arka_Banka_Kodi),
      Paguar_ne: String(state.Menyra_Pageses_ID),
      Operator: String(username),
      Qyteti_Kodi: state.Qyteti_Kodi,
      Afati_PagesesData: formatDate(state.Afati_PagesesData, true),
      KLFU_Pershkrim: state.KLFU_Pershkrim,
      Transportuesi_Nipt: state.Transportuesi_Nipt,
      Targa_e_Mjetit: state.Targa_e_Mjetit,
      Transportuesi_Adresa: state.Transportuesi_Adresa,
      Transportuesi_Pershkrim: state.Transportuesi_Pershkrim,
      Transport_Shenim: state.Transport_Shenim,
      Transport_Data: formatDate(state.Transport_Data, true),
      fisDocumentTypeKodi: state.fisDocumentTypeKodi,
      fisPaymentMethodTypeID: state.fisPaymentMethodTypeID
        ? Number(state.fisPaymentMethodTypeID)
        : null,
      FisInvoiceType: Number(state.FisInvoiceType),
      fisIsEinvoice: Number(state.fisIsEinvoice),
      fisProcessKodi: state.fisProcessKodi,
      fisIsCorrectiveInvoice: Number(state.fisIsCorrectiveInvoice),
      subsequentDelivery: Number(state.subsequentDelivery),
      fisCorrectiveInvTypeID: state.fisCorrectiveInvTypeID
        ? Number(state.fisCorrectiveInvTypeID)
        : null,
      fisIsReverseCharge: Number(state.fisIsReverseCharge),
      fisTypeOfSelfIssID: state.fisTypeOfSelfIssID
        ? Number(state.fisTypeOfSelfIssID)
        : null,
      subsequentDeliveryType: state.subsequentDeliveryType
        ? Number(state.subsequentDeliveryType)
        : null,
    });

    if (response.status === 200) {
      setState(defaultState);
      setDisabled(true);
      setButtonClicked(false);
      setSearchString("");

      const newEntryID = response.data.Id;
      showToast("Fatura u modifikua.", { severity: "success" });

      const dtlDataArray = getItemsForSecondRequest(
        rows,
        newEntryID,
        fromModify
      );

      // First request (PUT request)
      const responseDtl = await mainAxios.put(
        `/fature/shitje/dtl/bulk`,
        dtlDataArray
      );

      if (responseDtl.status === 200) {
        // Second request (POST request) only if the first one is successful
        const responseFD = await mainAxios.post(
          `/fature/shitje/f_insert/${newEntryID}`
        );

        if (responseFD.status === 200 && tipiDTL === "AR") {
          if (state.Arka_Banka_Kodi) {
            const arkaRequest =
              tipiArkes === "ARKA"
                ? mainAxios.post(`/ftsh/mandat/${newEntryID}`)
                : mainAxios.post(`/ftsh/mandat/banka/${newEntryID}`);
            await arkaRequest;
          }

          fetchFShitje(number);
          setCurrentAction(null);
          showToast("Flete Dalja u gjenerua.", { severity: "success" });
        } else {
          showToast("Nuk u gjenerua flete dalje.", { severity: "error" });
        }
      } else {
        showToast("Gabim gjatë regjistrimit të detajeve", {
          severity: "error",
        });
      }
    } else {
      showToast("Gabim gjatë regjistrimit të artikujve", { severity: "error" });
    }
  } catch (error) {
    console.error("Error in PUT request:", error);
    showToast("Gabim gjatë modifikimit të faturës", { severity: "error" });
  } finally {
    hideLoading();
  }
};

export { handleAdd, handleModify };
