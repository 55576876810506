import React, { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import mainAxios from "../../../services/axios";
import CloseIcon from "@mui/icons-material/Close";
import TableData from "./TableData";
import { TextField } from "@mui/material";

function MbylljeTeASH({ open, onClose }) {
  // const [dataPosBak, setDataPosBak] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month and pad
    const year = date.getFullYear(); // Get year
    return `${day}/${month}/${year}`; // Format as dd/mm/yyyy
  };

  const [data, setData] = useState([]);
  const [dataTime, setDataTime] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchDataTabelaFaturat = async () => {
    setLoading(true);
    const formattedDate = dataTime ? formatDate(dataTime) : "";
    const params = new URLSearchParams({
      MonBaze: "EUR",
      date: formattedDate,
    });
    try {
      const dataPaMbyllura = await mainAxios.get(
        `/mbyllje/ardhurave?${params}`
      );

      if (dataPaMbyllura.data.length === 0) {
        setData([]);
        return;
      } else {
        setData(dataPaMbyllura.data);
      }
    } catch (error) {
      console.error("Error fetching perdorues:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      {/* Updated styles for larger dialog */}
      <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-between", flexDirection: "column" }}
        >
          <span style={{ fontSize: "20px" }}>
            Mbyllje llogari te ardhurave dhe shpenzimeve
          </span>
        </div>

        <DialogActions>
          <Button variant="outlined" onClick={onClose} style={{ color: "red" }}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={{ height: "600px" }}>
            <TableData data={data} loading={loading} />
            <div
              className="flex"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginTop: "10px" }}>
                <TextField
                  type="date"
                  label="Data"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "200px" }}
                  value={dataTime}
                  onChange={(e) => setDataTime(e.target.value)}
                />
                <Button
                  disabled={!dataTime}
                  style={{ marginLeft: "10px" }}
                  variant="contained"
                  onClick={fetchDataTabelaFaturat}
                >
                  Kerko
                </Button>
              </div>
              <div className="flex" style={{ marginTop: "10px" }}>
                <Button variant="contained" onClick={fetchDataTabelaFaturat}>
                  Veprime Kontabel
                </Button>
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default MbylljeTeASH;
