import React, { useState } from "react";
import { DataTable } from "primereact/datatable"; // Import DataTable
import { CircularProgress } from "@mui/material";
import { Column } from "primereact/column"; // Import Column from primereact/column

export default function TableData({ data, loading }) {
  const [columns] = useState([
    { field: "ID", title: "ID" },
    { field: "Kodi", title: "Kodi" },
    { field: "sasia", title: "Sasia Hyrese" },
    { field: "Cmimi", title: "Cmimi", allowSum: true },
    { field: "Data", title: "Data" },
    { field: "SasiHProgresive", title: "Sasia e hyre Progresive" },
    { field: "SasiaD", title: "Sasia e dale" },
    { field: "SasiaGjendje", title: "Sasia Gjendje Progresive" },
    { field: "CmimiKosto", title: "Cmimi Kosto" },
    { field: "Shenim", title: "Shenim" },
    { field: "Error", title: "Error" },
  ]);

  // Dynamically create column headers and corresponding fields
  const dynamicColumns = columns.map((col, index) => (
    <Column key={index} field={col.field} header={col.title} />
  ));

  const fontOverlay = {
    fontSize: "11px",
  };

  return (
    <>
      {loading ? (
        <div
          className="squares-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          className="card"
          style={{ height: "90%", width: "100%", overflow: "auto" }}
        >
          <DataTable
            size="small"
            className="datatable_faturat_inside_main"
            gridKey="FaturatListe"
            value={data}
            responsiveLayout="scroll"
            style={fontOverlay}
            columnResizeMode="fit"
            showGridlines
            autoLayout={true}
            dataKey="Id"
            stickyHeader
            resizableColumns
            emptyMessage="Nuk ka te dhena"
            stripedRows
            reorderablecolumnsdatatable="true"
            scrollable
          >
            {dynamicColumns}
          </DataTable>
        </div>
      )}
    </>
  );
}
