// HeaderDesc.js
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { MultiSelect } from "primereact/multiselect";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  ListItem,
} from "@mui/material";
import ManageSearchRoundedIcon from "@mui/icons-material/ManageSearchRounded";
import Buttons from "../../EInvoice/Buttons/Buttons";

const HeaderDesc = ({
  dataNga,
  setDataNga,
  dataDeri,
  setDataDeri,
  performSearch,
  selectedColumns,
  columns,
  fromrivlersimi,
  onColumnToggle,
  fromEInvoice,
  setData,
  data,
}) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleClear = () => {
    setSelectedOption(""); // Clears the selected option
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        className={
          fromrivlersimi
            ? "button_header_datatable_shikoFaturat2"
            : "button_header_datatable_shikoFaturat"
        }
      >
        {fromrivlersimi ? (
          <div
            style={{ display: "flex", alignItems: "center", width: "250px" }}
          >
            <FormControl fullWidth style={{ marginRight: "10px" }}>
              <InputLabel id="document-select-label">Dokument</InputLabel>
              <Select
                labelId="document-select-label"
                value={selectedOption}
                label="Dokument"
                onChange={handleChange}
              >
                <MenuItem value="Klient">Klient</MenuItem>
                <MenuItem value="Furnitor">Furnitor</MenuItem>
                <MenuItem value="Arka">Arka</MenuItem>
                <MenuItem value="Banka">Banka</MenuItem>

                {/* Clear button inside the select menu */}
                <MenuItem onClick={handleClear}>
                  <ListItem button>
                    <Button variant="outlined" fullWidth>
                      Pastro
                    </Button>
                  </ListItem>
                </MenuItem>
              </Select>
            </FormControl>

            <TextField
              type="date"
              label="Data"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "200px" }}
              value={data}
              onChange={(e) => setData(e.target.value)}
            />
          </div>
        ) : (
          <>
            <TextField
              style={{ height: "100%" }}
              type="date"
              label="Nga Data"
              InputLabelProps={{
                shrink: true,
              }}
              value={dataNga}
              onChange={(e) => setDataNga(e.target.value)}
            />
            <TextField
              style={{ height: "100%" }}
              type="date"
              label="Deri Date"
              InputLabelProps={{
                shrink: true,
              }}
              value={dataDeri}
              onChange={(e) => setDataDeri(e.target.value)}
              // style={isMobile ? { width: "50px" } : ""}
            />{" "}
          </>
        )}
        {fromrivlersimi && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "250px",
              margin: "0 10px 0 10px",
              justifyContent: "space-between",
            }}
          >
            <FormControl fullWidth style={{ marginRight: "10px" }}>
              <InputLabel id="document-select-label">Monedha</InputLabel>
              <Select
                labelId="document-select-label"
                value={selectedOption}
                label="Dokument"
                onChange={handleChange}
              >
                <MenuItem value="Klient">Klient</MenuItem>
                <MenuItem value="Furnitor">Furnitor</MenuItem>
                <MenuItem value="Arka">Arka</MenuItem>
                <MenuItem value="Banka">Banka</MenuItem>

                {/* Clear button inside the select menu */}
                <MenuItem onClick={handleClear}>
                  <ListItem button>
                    <Button variant="outlined" fullWidth>
                      Pastro
                    </Button>
                  </ListItem>
                </MenuItem>
              </Select>
            </FormControl>

            <TextField
              style={{ height: "100%" }}
              type="number"
              label="Kursi"

              // value={dataNga}
              // onChange={(e) => setDataNga(e.target.value)}
            />
          </div>
        )}

        <Button
          variant="contained"
          style={{ height: "100%" }}
          disabled={!data && !dataNga && !dataDeri}
          onClick={(e) => {
            e.preventDefault();
            performSearch();
            // setDataNga("");
            // setDataDeri("");
          }}
        >
          <ManageSearchRoundedIcon />
        </Button>

        {fromrivlersimi && (
          <>
            {" "}
            <Button
              variant="contained"
              style={{ height: "100%", marginLeft: "10px" }}
              disabled={!data && !dataNga && !dataDeri}
              onClick={(e) => {
                e.preventDefault();
                performSearch();
                // setDataNga("");
                // setDataDeri("");
              }}
            >
              Nderto Veprime Kontabel
            </Button>
          </>
        )}
      </div>
      {fromEInvoice ? (
        // <Buttons
        //   disabledData={disabledData}
        //   setDecission={setDecission}
        //   onTestiChange={onTestiChange}
        //   selectedProduct={selectedProduct}
        // />
        <></>
      ) : (
        <MultiSelect
          style={{ height: "100%", width: "3em" }}
          // className="selectFature"
          value={selectedColumns}
          options={columns}
          optionLabel="title"
          onChange={onColumnToggle}
        />
      )}
    </div>
  );
};

export default HeaderDesc;
