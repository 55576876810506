import React from "react";
import { Checkbox, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TableComponent from "./Table";
import Button from "@mui/material/Button";

const MemoizedTableComponent = React.memo(TableComponent);

export default function DialogTable({
  selectAll,
  ngaData,
  setNgaData,
  deriData,
  setDeriData,
  loading,
  disabled,
  total,
  checkboxLabels,
  handleDateChange,
  fetchDataTabelaFaturat,
  handleSelectAllChange,
  memoizedSelectedCheckboxes,
  handleCheckboxChange,
  memoizedData,
}) {
  return (
    <div
      className="card"
      style={{
        height: "90%",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Grid item>
        <Grid container direction="row" sx={{ marginTop: 2 }}>
          <Box sx={{ flexGrow: 2 }}>
            <Grid container direction="column" spacing={1.5}>
              <Grid container direction="row" item xs={12} alignItems="center">
                <TextField
                  required
                  variant="outlined"
                  type="date"
                  label="Data Nga"
                  InputLabelProps={{ shrink: true }}
                  value={ngaData}
                  onChange={(e) => handleDateChange(setNgaData, e.target.value)}
                  size="small"
                  sx={{
                    width: "30%",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                />
                <TextField
                  required
                  variant="outlined"
                  type="date"
                  label="Data Deri"
                  InputLabelProps={{ shrink: true }}
                  value={deriData}
                  onChange={(e) =>
                    handleDateChange(setDeriData, e.target.value)
                  }
                  size="small"
                  sx={{
                    width: "30%",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                />

                <Button
                  variant="outlined"
                  onClick={fetchDataTabelaFaturat}
                  disabled={disabled}
                >
                  Kerko
                </Button>
              </Grid>
              <Grid container direction="row" item xs={12} alignItems="center">
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
                <Typography variant="body1">Selekto te gjitha</Typography>
              </Grid>
              <Grid
                container
                direction="column"
                item
                alignItems="center"
                style={{ width: "90%", marginLeft: "40px" }}
              >
                {checkboxLabels.map((label) => (
                  <div
                    key={label}
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="flex" style={{ alignItems: "center" }}>
                      <Checkbox
                        name={label}
                        checked={memoizedSelectedCheckboxes.has(label)}
                        onChange={handleCheckboxChange}
                      />
                      <Typography variant="body1">{label}</Typography>
                    </div>
                    <Typography variant="body1">0</Typography>
                  </div>
                ))}
                <div
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body1">{total} Dokumenta</Typography>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <MemoizedTableComponent data={memoizedData} loading={loading} />
    </div>
  );
}
