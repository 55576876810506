import React, { useState } from "react";
import { DataTable } from "primereact/datatable"; // Import DataTable
import { CircularProgress } from "@mui/material";
import { Column } from "primereact/column"; // Import Column from primereact/column

export default function TableData({ data, loading, searchTerm }) {
  console.log(data, "data");
  const [columns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "Barkod", title: "BARKOD" },
    { field: "Pershkrim", title: "Klienti" },
    { field: "Pike_Te_Mbartura", title: "Pike te mbartura", allowSum: true },
    { field: "Total", title: "Pike Total", allowSum: true },
    { field: "Dhurata", title: "Dhurata", allowSum: true },
    { field: "Pike", title: "Pike", allowSum: true },
  ]);

  // Filter data based on the search term
  const filteredData = data.filter((item) =>
    columns.some((col) =>
      item[col.field]
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );

  // Dynamically create column headers and corresponding fields
  const dynamicColumns = columns.map((col, index) => (
    <Column key={index} field={col.field} header={col.title} />
  ));

  const fontOverlay = {
    fontSize: "11px",
  };

  return (
    <>
      {loading ? (
        <div
          className="squares-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          className="card"
          style={{ height: "90%", width: "100%", overflow: "hidden" }}
        >
          <DataTable
            size="small"
            className="datatable_faturat_inside_main"
            gridKey="FaturatListe"
            value={filteredData}
            responsiveLayout="scroll"
            style={fontOverlay}
            columnResizeMode="fit"
            showGridlines
            autoLayout={true}
            dataKey="Id"
            stickyHeader
            resizableColumns
            emptyMessage="Nuk ka te dhena"
            stripedRows
            reorderablecolumnsdatatable="true"
            scrollable
          >
            {dynamicColumns}
          </DataTable>
        </div>
      )}
    </>
  );
}
