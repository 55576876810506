import React, { useState, useCallback, useMemo, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import mainAxios from "../../../services/axios";
import CloseIcon from "@mui/icons-material/Close";
import DialogTable from "./Dialog";
import { Divider } from "@mui/material";
import { useToast } from "../../../components/context/ToastContext";

function PostimDitar({ open, onClose }) {
  const showToast = useToast();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [ngaData, setNgaData] = useState("");
  const [deriData, setDeriData] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [total, setTotal] = useState(0);

  const convertDateFormat = (date) => {
    const [year, month, day] = date.split("-");
    return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
  };

  const checkboxLabels = [
    "FATURE_SHITJE",
    "FATURE_BLERJE",
    "FLETE_HYRJE",
    "FLETE_DALJE",
    "ARKA",
    "BANKA",
    "IMPORT",
    "Veprime_Kontabel",
  ];

  // pasi te kemi ber postim, cpostim etj duhet te bejme fetch te dhenat e reja nga tabel Ditari
  // const fetchData = async (from, to) => {
  //   setLoading(true);
  //   try {
  //     const selectedDocs = Array.from(selectedCheckboxes);
  //     if (selectedDocs.length === 0) {
  //       setData([]);
  //       setLoading(false);
  //       return;
  //     }

  //     const response = await mainAxios(
  //       `/ditari?tabela=FATURE_BLERJE&tabela=BANKA&tabela=FATURE_SHITJE&tabela=ARKA&tabela=Veprime_Kontabel&tabela=IMPORT&tabela=FLETE_DALJE&tabela=FLETE_HYRJE&ngaData=2023-01-01&deriData=2025-02-01`
  //     );
  //     setData(response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchDataTabelaFaturat = async () => {
    setLoading(true);
    try {
      const formattedNgaData = convertDateFormat(ngaData);
      const formattedDeriData = convertDateFormat(deriData);
      const selectedTables = Array.from(selectedCheckboxes).join("&tabela=");
      const query = `/ditari/merr-pa-postuar?tabela=${selectedTables}&ngaData=${formattedNgaData}&deriData=${formattedDeriData}`;

      const dataPaMbyllura = await mainAxios.get(query);

      if (
        Array.isArray(dataPaMbyllura.data.documents) &&
        dataPaMbyllura.data.documents.length > 0
      ) {
        setTotal(dataPaMbyllura.data.total);
        setData(dataPaMbyllura.data.documents);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const postim = async () => {
    setLoading(true);
    try {
      const formattedNgaData = convertDateFormat(ngaData);
      const formattedDeriData = convertDateFormat(deriData);
      const selectedTables = Array.from(selectedCheckboxes).join("&tabela=");
      const query = `/ditari/postim?tabela=${selectedTables}&ngaData=${formattedNgaData}&deriData=${formattedDeriData}`;

      const dataPaMbyllura = await mainAxios.post(query);

      if (
        Array.isArray(dataPaMbyllura.data.documents) &&
        dataPaMbyllura.data.documents.length > 0
      ) {
        setData(dataPaMbyllura.data.documents);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const postimTeGjitha = async () => {
    setLoading(true);
    try {
      const selectedTables = Array.from(selectedCheckboxes).join("&tabela=");
      const query = `/ditari/postim?tabela=${selectedTables}&ngaData=1990-01-28&deriData=2100-01-28`;

      const dataPaMbyllura = await mainAxios.post(query);

      if (
        Array.isArray(dataPaMbyllura.data.documents) &&
        dataPaMbyllura.data.documents.length > 0
      ) {
        setData(dataPaMbyllura.data.documents);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const cpostim = async () => {
    setLoading(true);
    try {
      const formattedNgaData = convertDateFormat(ngaData);
      const formattedDeriData = convertDateFormat(deriData);
      const selectedTables = Array.from(selectedCheckboxes).join("&tabela=");
      const query = `/ditari/cpostim?tabela=${selectedTables}&ngaData=${formattedNgaData}&deriData=${formattedDeriData}`;

      const dataPaMbyllura = await mainAxios.delete(query);

      if (
        Array.isArray(dataPaMbyllura.data.documents) &&
        dataPaMbyllura.data.documents.length > 0
      ) {
        setData(dataPaMbyllura.data.documents);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const cpostimTeGjitha = async () => {
    setLoading(true);
    try {
      const response = await mainAxios.delete(`/ditari/cpostim-te-gjitha`);
      if (response.status === 200) {
        showToast("Te Gjitha u fshine nga Ditari", { severity: "info" });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = useCallback((setDate, value) => {
    setDate(value);
  }, []);

  useEffect(() => {
    if (ngaData && deriData && selectedCheckboxes.size > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [ngaData, deriData, selectedCheckboxes]);

  const handleSelectAllChange = useCallback((event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setSelectedCheckboxes(new Set(checked ? checkboxLabels : []));
  }, []);

  const handleCheckboxChange = useCallback((event) => {
    const { name, checked } = event.target;
    setSelectedCheckboxes((prev) => {
      const newSet = new Set(prev);
      if (checked) {
        newSet.add(name);
      } else {
        newSet.delete(name);
        setSelectAll(false);
      }
      return newSet;
    });
  }, []);

  const memoizedData = useMemo(() => data, [data]);
  const memoizedSelectedCheckboxes = useMemo(
    () => selectedCheckboxes,
    [selectedCheckboxes]
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      {/* Updated styles for larger dialog */}
      <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-between", flexDirection: "column" }}
        >
          <span style={{ fontSize: "20px" }}>Postim ne ditar </span>
        </div>

        <DialogActions>
          <Button variant="outlined" onClick={onClose} style={{ color: "red" }}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={{ height: "600px" }}>
            <DialogTable
              selectAll={selectAll}
              ngaData={ngaData}
              setNgaData={setNgaData}
              deriData={deriData}
              setDeriData={setDeriData}
              data={data}
              loading={loading}
              disabled={disabled}
              total={total}
              checkboxLabels={checkboxLabels}
              memoizedData={memoizedData}
              memoizedSelectedCheckboxes={memoizedSelectedCheckboxes}
              handleSelectAllChange={handleSelectAllChange}
              handleCheckboxChange={handleCheckboxChange}
              handleDateChange={handleDateChange}
              fetchDataTabelaFaturat={fetchDataTabelaFaturat}
            />

            <div
              className="flex"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div></div>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button color="success" variant="outlined" onClick={postim}>
                  Postim
                </Button>
                <Button
                  color="success"
                  variant="outlined"
                  style={{ marginLeft: "10px" }}
                  onClick={postimTeGjitha}
                >
                  Posto Cdo Gje
                </Button>
                <Divider
                  sx={{
                    width: "2px",
                    height: "30px",
                    marginLeft: "10px",
                    backgroundColor: "#000",
                    borderRight: "2px solid #000",
                  }}
                />
                <Button
                  color="error"
                  variant="outlined"
                  style={{ marginLeft: "10px" }}
                  onClick={cpostimTeGjitha}
                >
                  Cpostim Te gjitha
                </Button>
                <Button
                  color="error"
                  variant="outlined"
                  style={{ marginLeft: "10px" }}
                  onClick={cpostim}
                >
                  Cpostim
                </Button>
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default PostimDitar;
