import React, { useState, useEffect, useRef, useContext } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import useStorage from "../../../hooks/useStorage";
import mainAxios from "../../../services/axios";
import { ContextMenu } from "primereact/contextmenu";
import "../../../components/ShikoFaturat/SearchFaturat.scss";
import "../../../components/ListaArtikujve/ModalList.scss";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";
import HeaderMobile from "../FatureThjeshtuar/Grid/HeaderMobile";
import HeaderDesc from "../FatureThjeshtuar/Grid/HeaderDesc";
import { useToast } from "../../../components/context/ToastContext";

const List = ({ rows, setRows, columns, gridKey, contextKey, isMobile }) => {
  const [disabledData, setDisabledData] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState({});

  const onTestiChange = async (newTesti, decission) => {
    if (!newTesti || newTesti === undefined || newTesti.EIC === undefined) {
      showToast("Nuk keni zgjedhur fature");
      return;
    }
    try {
      // gjendet eic i fatures me Id = x
      const indexResponse = await mainAxios.get(
        `/invoice_decission/${newTesti.EIC}/${decission}`
      );

      if (indexResponse.status === 200) {
        performSearch();
        showToast("Fatura u zgjodh", { hideProgressBar: true });
        // performSearch();
        // setState(indexResponse.data);
        // showToast("Fatura u zgjodh", { hideProgressBar: true });
        // const responseDtl = await mainAxios.get(
        //   `/fature/shitje/dtl/${newTesti.Id}`
        // );
        // if (responseDtl.status === 200) {
        //   setDtlState(responseDtl.data);
        // }
      }
    } catch (error) {
      showToast("Error ne zgjedhjen e fatures.", { severity: "error" });
      console.error("Error fetching data:", error);
    }
  };

  const [dataNga, setDataNga] = useState("");
  const [dataDeri, setDataDeri] = useState("");
  const showToast = useToast();
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const cm = useRef(null);

  useEffect(() => {
    const currentDate = new Date();
    setDataDeri(currentDate.toISOString().split("T")[0]);
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
    const formattedDate = sevenDaysAgo.toISOString().split("T")[0];
    setDataNga(formattedDate);
  }, []);

  const performSearch = async () => {
    showLoading();

    try {
      const response = await mainAxios(`/${contextKey}/${dataNga}/${dataDeri}`);

      const modifiedDataAR = response.data.map((item) => ({
        ...item,

        Data: item.Data ? item.Data.split("T")[0] : "",
      }));
      setRows(modifiedDataAR);
      if (modifiedDataAR.length > 0) {
        setDisabledData(false);
      }
    } catch (err) {
      console.error(err);
      setRows([]);
      setDisabledData(true);

      showToast("Ndodhi problem!");
    } finally {
      hideLoading();
    }
  };

  const [selectedColumns, setselectedcolumns] = useStorage(
    columns,
    `kolonatEfaturave_${gridKey}`
  );

  const dynamicColumns = selectedColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.title}
        className={`truncate-text ${col.field}`} // Add a class name for each column
        sortable
        style={{
          maxWidth: col.maxWidth,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      />
    );
  });

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setselectedcolumns(orderedSelectedColumns);
  };

  const fontOverlay = {
    fontSize: "11px",
  };

  // Context menu model
  const menuModel = [
    {
      label: "Prano",
      icon: (
        <span
          className="pi pi-fw pi-check"
          style={{ color: "green", marginRight: "10px" }}
        ></span>
      ), // Inline style for green color
      command: () => onTestiChange(selectedProduct, "ACCEPTED"),
    },
    {
      label: "Refuzo",
      icon: (
        <span
          className="pi pi-fw pi-times"
          style={{
            color: "red",
            marginRight: "10px",
          }}
        ></span>
      ), // Inline style for green color
      command: () => onTestiChange(selectedProduct, "REFUSED"),
    },
    {
      label: "PDF",
      icon: (
        <span
          className="pi pi-fw pi-file-pdf"
          style={{
            color: "red",
            marginRight: "10px",
          }}
        ></span>
      ),
      // command: () => onTestiChange(selectedProduct, "REFUSED"),
    },
    {
      label: "EXEL",
      icon: (
        <span
          className="pi pi-fw pi-file-excel"
          style={{
            color: "green",
            marginRight: "10px",
          }}
        ></span>
      ),
      // command: () => onTestiChange(selectedProduct, "EXCEL"),
    },
  ];

  return (
    <>
      <ContextMenu model={menuModel} ref={cm} />
      <div className="card_shiko_faturat_Einvoice">
        <div className="datatable_faturat_main">
          <DataTable
            size="small"
            className="datatable_faturat_inside_main_Einvoice"
            gridKey="EInvoiceListe"
            onContextMenu={
              disabledData ? undefined : (e) => cm.current.show(e.originalEvent)
            }
            contextMenuSelection={selectedProduct}
            onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
            header={
              isMobile ? (
                <HeaderMobile
                  dataNga={dataNga}
                  setDataNga={setDataNga}
                  dataDeri={dataDeri}
                  setDataDeri={setDataDeri}
                  performSearch={performSearch}
                  selectedColumns={selectedColumns}
                  columns={columns}
                  fromEInvoice={true}
                  onTestiChange={onTestiChange}
                  onColumnToggle={onColumnToggle}
                  selectedProduct={selectedProduct}
                  disabledData={disabledData}
                />
              ) : (
                <HeaderDesc
                  dataNga={dataNga}
                  setDataNga={setDataNga}
                  dataDeri={dataDeri}
                  setDataDeri={setDataDeri}
                  performSearch={performSearch}
                  selectedColumns={selectedColumns}
                  fromEInvoice={true}
                  onTestiChange={onTestiChange}
                  selectedProduct={selectedProduct}
                  columns={columns}
                  onColumnToggle={onColumnToggle}
                  disabledData={disabledData}
                />
              )
            }
            autoLayout={true}
            selectionMode="single"
            onSelectionChange={(e) => setSelectedProduct(e.value.EIC)}
            scrollable
            value={rows}
            responsiveLayout="scroll"
            style={fontOverlay}
          >
            {dynamicColumns}
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default List;
