import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import ConstructionIcon from "@mui/icons-material/Construction";

const DropdownButton = ({ dropdownList, onSelectOption, currentId }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (title) => {
    onSelectOption(title, currentId); // Call the function passed from parent on selection
    setAnchorEl(null); // Close the dropdown
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the dropdown without selecting anything
  };

  return (
    <div>
      <Button
        startIcon={<ConstructionIcon />}
        onClick={handleButtonClick}
        variant="outlined"
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {dropdownList.map((item, index) => (
          <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DropdownButton;
