import React, { useCallback, memo } from "react";
import { Panel } from "primereact/panel";
import Template from "../../dynamicComponent/Template";
import TabsContainer from "../Tabs/TabsContainer";

const TopPanelFsh = ({
  isPanelToggleable,
  setIsPanelToggleable,
  isMobile,
  currentId,
  nxtBtnDisable,
  prvBtnDisable,
  shikoFaturen,
  handleTestiChange,
  columnsSF,
  visible,
  setVisible,
  toggleState,
  disabled,
  setBankaInfo,
  state,
  handleChange,
  setSearchString,
  searchString,
  handleFunctionKlienti,
  qytetet,
  shtetet,
  setState,
  klienti,
  setTipiArkes,
  setSelectedArkaMon,
  rows,
  menyrapageses,
  tipiArkes,
  buttonClicked,
  formatDate,
  updateDepKodi,
  fetchFShitje,
  PaneliKryesor,
  PaneliDytesor,
  BtnPrint,
  BtnFiskal,
  TabiKryesor,
  fatureName,
  contextKey,
  gridKey,
  fromVPK,
  smallTabsKM,
  fromOfert,
}) => {
  const handleToggle = useCallback(() => {
    setIsPanelToggleable((prev) => !prev);
  }, [setIsPanelToggleable]);

  const headerTemplate = (
    <Template
      options={{
        collapsed: isPanelToggleable,
        onTogglerClick: handleToggle,
      }}
      isMobile={isMobile}
      fatureName={fatureName}
      currentId={currentId}
      createdBy={state.Operator}
      nxtBtnDisable={nxtBtnDisable}
      prvBtnDisable={prvBtnDisable}
      shikoFaturen={shikoFaturen}
      handleTestiChange={handleTestiChange}
      columnsSF={columnsSF}
      contextKey={contextKey}
      visible={visible}
      setVisible={setVisible}
      gridKey={gridKey}
      fatureshitje={true}
    />
  );

  const renderDesktopView = () => (
    <>
      <div className="paneli-tabeve">
        <div className="paneli-tabeve1">{PaneliKryesor}</div>
        <div className="paneli-tabeve2">{!fromVPK && PaneliDytesor}</div>
        <div className="paneli-fis-print">
          {BtnPrint}
          {BtnFiskal}
        </div>
      </div>
      <div className="below-tabs">
        <div className="below-tabs1">{TabiKryesor}</div>
        <div className="below-tabs2">
          {fromVPK && smallTabsKM}
          {!fromVPK && (
            <TabsContainer
              toggleState={toggleState}
              disabled={disabled}
              fromVPK={fromVPK}
              state={state}
              setBankaInfo={setBankaInfo}
              handleChange={handleChange}
              setSearchString={setSearchString}
              searchString={searchString}
              handleFunctionKlienti={handleFunctionKlienti}
              qytetet={qytetet}
              shtetet={shtetet}
              setState={setState}
              klienti={klienti}
              setTipiArkes={setTipiArkes}
              setSelectedArkaMon={setSelectedArkaMon}
              rows={rows}
              menyrapageses={menyrapageses}
              tipiArkes={tipiArkes}
              buttonClicked={buttonClicked}
              formatDate={formatDate}
              updateDepKodi={updateDepKodi}
              fetchFShitje={fetchFShitje}
            />
          )}
        </div>
        <div className="below-tabs3"></div>
      </div>
    </>
  );

  const renderMobileView = () => (
    <>
      <div className="mobile-panel-header">
        {PaneliKryesor}
        <div className="mobile-buttons">{BtnFiskal}</div>
      </div>
      <div className="mobile-tab">{TabiKryesor}</div>
      <div className="mobile-panel-footer">
        {PaneliDytesor}
        {BtnPrint}
      </div>
      <div className="mobile-tabs-container">
        <TabsContainer
          toggleState={toggleState}
          disabled={disabled}
          state={state}
          setBankaInfo={setBankaInfo}
          handleChange={handleChange}
          setSearchString={setSearchString}
          searchString={searchString}
          handleFunctionKlienti={handleFunctionKlienti}
          qytetet={qytetet}
          shtetet={shtetet}
          klienti={klienti}
          setState={setState}
          setTipiArkes={setTipiArkes}
          setSelectedArkaMon={setSelectedArkaMon}
          rows={rows}
          menyrapageses={menyrapageses}
          tipiArkes={tipiArkes}
          buttonClicked={buttonClicked}
          formatDate={formatDate}
          updateDepKodi={updateDepKodi}
          fetchFShitje={fetchFShitje}
        />
      </div>
    </>
  );

  return (
    <div className="top_panel_fsh">
      <Panel
        headerTemplate={headerTemplate}
        toggleable
        collapsed={isPanelToggleable}
        onToggle={handleToggle}
        style={{ height: "100%" }}
      >
        <div className="bg-white panel-content">
          {isMobile ? renderMobileView() : renderDesktopView()}
        </div>
      </Panel>
    </div>
  );
};

export default memo(TopPanelFsh);
