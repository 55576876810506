import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import mainAxios from "../../../services/axios";
import CloseIcon from "@mui/icons-material/Close";
import TableData from "./TableData";
import * as XLSX from "xlsx"; // Import xlsx for Excel export

function KostoKalkulim({ open, onClose }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchDataTabelaFaturat = async () => {
    setLoading(true);
    try {
      const dataPaMbyllura = await mainAxios.get(`/kostomesatare`);
      if (dataPaMbyllura.data.length === 0) {
        setData([]);
        return;
      } else {
        setData(dataPaMbyllura.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportToExcel = () => {
    if (data.length === 0) return;

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    // Create a workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "KostoKalkulim");

    // Generate and download Excel file
    XLSX.writeFile(workbook, "KostoKalkulim.xlsx");
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontSize: "20px" }}>Kalkulo Kosto</span>
        </div>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} style={{ color: "red" }}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={{ height: "600px" }}>
            <TableData data={data} loading={loading} />
            <div
              className="flex"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div></div>
              <div style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
                <Button variant="outlined" onClick={fetchDataTabelaFaturat}>
                  Kalkulim
                </Button>
                <Button
                  variant="outlined"
                  disabled={data.length === 0}
                  onClick={handleExportToExcel}
                >
                  Export to Excel
                </Button>
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default KostoKalkulim;
