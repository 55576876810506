import React, { useEffect, useState, memo, useContext } from "react";
import PrimeGrid from "../../components/primeReact/PrimeGrid";
import { Panel } from "primereact/panel";
import { TextField } from "@mui/material";
import mainAxios from "../../services/axios";
import NavBar from "../../components/Navbar/NavBar";
import SideBar from "../../components/Navbar/SideBar";
import MainTab from "../fature-blerje/tabet/MainTab/MainTab";
import PaneliTabeve from "../fature-blerje/tabet/PaneliTabeve";
import PaneliTab2 from "../fature-blerje/tabet/PaneliTab2";
import PrintoPdfFleteHyrje from "../../components/ReactPDF/FleteHyrjePdf/PrintoPdf";
import "../fature-blerje/FatureBlerje.scss";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { LoadingOverlayContext } from "../../components/LoadingOverlay";
import { useSelector } from "react-redux";
import Template from "../dynamicComponent/Template";
import { shtoArtikull, calculateData } from "../dynamicComponent/FleteArtCalc";
import { useLocation } from "react-router-dom";
import { getIndexFirstFh } from "../../store/globalSlice";
import { useDispatch } from "react-redux";
import { useToast } from "../../components/context/ToastContext";

const Inventar = ({
  currentAction,
  setCurrentAction,
  isOnline,
  isMobile,
  disabled,
  setDisabled,
  drawerOpen,
  setDrawerOpen,
}) => {
  const showToast = useToast();
  const dispatch = useDispatch();
  const [fatureDisabled, setFatureDisabled] = useState(true);
  const [hidePupUp, setHidePupUp] = useState(false);
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const monedhat = useSelector((state) => state.globalSlice.monedha);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [nxtBtnDisable, setNxtBtnDisable] = useState(false);
  const [isPanelToggleable, setIsPanelToggleable] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [lastId, setLastId] = useState("");
  const [deletedRowIds, setDeletedRowIds] = useState([]);
  const location = useLocation();
  const { nmrFB, fromFatureBlerje } = location.state || {};

  //thirrja e userit aktual
  const [username, setUsername] = useState("");

  // Define fetchData outside the useEffect
  const fetchData = async () => {
    const storedData = localStorage.getItem("loginData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUsername(parsedData.username);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // redux magazina (get requesti i Magazines)
  const magazina = useSelector((state) => state.globalSlice.magazina);
  const defaultMag = magazina.map((item) => item.Kodi);
  let numMag = magazina.length;

  // redux mer indexin e fatures se pare
  const firstFleteHIndex = useSelector((state) => state.globalSlice.getFirstFH);

  // Ky funksion therritet kur butoni "Shtim" klikohet
  const onAddClick = () => {
    setCurrentAction("add");
    setCurrentId(lastId);
  };

  // Ky funksion therritet kur butoni "Modifiko" klikohet
  const onModifyClick = () => {
    // setFiskalDisable(true)
    setCurrentAction("modify");
    setDisabled(false);
    setButtonClicked(true);
  };

  const [rows, setRows] = useState([]);

  const [index, setIndex] = useState(0);

  const columnsSF = [
    { field: "Id", title: "Id" },
    { field: "Kodi", title: "Kodi" },
    { field: "Data", title: "Data" },
    { field: "KLFU_Kodi", title: "Shenim 1" },
    { field: "KLFU_Pershkrim", title: "Shenim 2" },
    { field: "Vlera", title: "Vlera" },
    { field: "Operator", title: "Operator" },
  ];

  const [columns, setColumns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "BarKod", title: "BarKod" },
    { field: "NrSerik", title: "NrSerik", filter: "agMultiColumnFilter" },
    { field: "Pershkrim", title: "Pershkrim", impMinWidth: true },
    { field: "Shenim", title: "Shenim" },
    { field: "Sasia", title: "Sasia", allowSum: true },
    { field: "Njesi_Kodi", title: "Njesi_Kodi" },
    { field: "Cmimi", title: "Cmimi" },
    { field: "CmimiShites", title: "Cmimi Shites", allowSum: true },
    { field: "Vlera", title: "Vlera", allowSum: true },
    { field: "Magazina_Kodi", title: "Magazina" },
  ]);

  const defaultColumnsStorage = columns.map((column) => column.title);

  const handleShtoArtikull = (artikull) => {
    shtoArtikull(artikull, rows, setRows, state);
  };

  const handleCalculateData = (field, item) => {
    return calculateData(field, item, state);
  };

  const formatDate = (dateString, addTime = false) => {
    let date;

    if (dateString instanceof Date) {
      date = dateString;
    } else if (typeof dateString === "string") {
      date = new Date(dateString);

      if (isNaN(date.getTime())) {
        date = new Date();
      }
    } else {
      date = new Date();
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    if (addTime) {
      return `${formattedDate}T00:00:00`;
    }

    return formattedDate;
  };

  let defaultState = {
    Magazina_Kodi: defaultMag[0],
    Data: "",
    Kodi: "",
    Shenim: "",
  };

  const [state, setState] = useState(defaultState);
  const [responseData, setResponseData] = useState([]);
  const [lastObject, setLastObject] = useState(null);
  const [number, setNumber] = useState(1);
  const [prvBtnDisable, setPrvBtnDisable] = useState(false);

  useEffect(() => {
    if (fromFatureBlerje) {
      setNumber(nmrFB);
      fetchFHyrje(nmrFB);
    } else {
      fetchFHyrje(number);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeValue = (newValue) => {
    setCurrentId(newValue);
    if (newValue) {
      setFatureDisabled(false);
    } else {
      setFatureDisabled(true);
    }
  };

  const fetchFHyrje = async (number) => {
    try {
      showLoading();
      const response = await mainAxios(`/inventar/${number}`);

      const responseTotalRows = await dispatch(getIndexFirstFh("/first/inv"));
      const total_data_num = responseTotalRows.payload.total_rows;

      // nqs ska data empty [] make default display no data
      if (!response.data || Object.keys(response.data).length === 0) {
        // If there's no data in the response, handle it accordingly
        setFatureDisabled(true);
        setRows([]);
        setCurrentId(null);
        if (total_data_num === 0 || total_data_num === 1) {
          setNxtBtnDisable(true);
          setPrvBtnDisable(true);
        }
        return;
      }

      handleChangeValue(response.data.Id);

      if (number > 1) {
        setPrvBtnDisable(false);
      } else if (number <= 1) {
        setPrvBtnDisable(true);
      }
      if (number === 1) {
        setPrvBtnDisable(true);
      }
      if (number !== total_data_num) {
        setNxtBtnDisable(false);
      } else {
        setNxtBtnDisable(true);
      }

      const responseData = response.data;
      if (responseData) {
        // Fetch detailed data here
        const dtlResponse = await mainAxios(`/inventar/dtl/${responseData.Id}`);
        const dtlData = dtlResponse.data;
        setState(responseData);
        setResponseData(responseData);
        setLastObject(responseData);
        setRows(dtlData);
        setNumber(number);
        if (number === 1) {
          setLastId(response.data.Id + 1);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      hideLoading();
    }
  };

  const shikoFaturen = async (type) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }

    const currentNumber = Number(number);

    if (type === "first") {
      setNumber(firstFleteHIndex.total_rows);
      fetchFHyrje(firstFleteHIndex.total_rows);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "increment") {
      setNumber(number + 1);
      const nextNumber = currentNumber + 1;
      fetchFHyrje(nextNumber);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "decrement") {
      setNumber(number - 1);
      const prevNumber = currentNumber - 1;
      fetchFHyrje(prevNumber);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "last") {
      setNumber(1);
      fetchFHyrje(1);
      setDisabled(true);
      setButtonClicked(false);
    }
  };

  let numberIndex;
  const handleTestiChange = async (newTesti) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }
    try {
      //gjendet numri i fatures me Id = x
      const indexResponse = await mainAxios.get(
        `/inventar/index/${newTesti.Id}`
      );

      if (indexResponse.status === 200) {
        numberIndex = indexResponse.data.index;
        fetchFHyrje(numberIndex);

        showToast("Inventari u zgjodh", { hideProgressBar: true });
      }
    } catch (error) {
      showToast("Error ne zgjedhjen e inventarit.", { severity: "error" });

      console.error("Error fetching data:", error);
    } finally {
      setVisible(false);
    }
  };

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  //Butoni "Regjistro" perdor currentAction qe te vendosi, ca requesti te bej.
  const handleRegister = async () => {
    if (!state.Magazina_Kodi) {
      showToast("Cakto Magazinen!", { hideProgressBar: true });
      return;
    }

    if (rows.length === 0) {
      showToast("Shto Artikuj ose anullo faturen!");
      return;
    } else {
      try {
        for (const id of deletedRowIds) {
          await mainAxios.delete(`/inventar/dtl/${id}`);
        }
      } catch (error) {
        console.error("Error deleting row:", error);
      } finally {
        setDeletedRowIds([]);
      }
    }

    if (currentAction === "add") {
      handleAdd();
      setCurrentAction(null);
    } else if (currentAction === "modify") {
      handleModify();
      setCurrentAction(null);
    }
  };

  // Kur Klikohet butoni i "SHTIM"
  const handleAdd = async () => {
    const getItemsForSecondRequest = (rows, newEntryID) => {
      return rows.map((row) => {
        let item = {
          Master_Id: newEntryID,
          Art_ID: row.Art_ID,
          Kodi: row.Kodi,
          Kodi1: row.Kodi1,
          Kodi2: row.Kodi2,
          BarKod: row.BarKod,
          Magazina_Kodi: row.Magazina_Kodi,
          Pershkrim: row.Pershkrim,
          Njesi_Kodi: row.Njesi_Kodi,
          Koeficient: row.Koeficient,
          Cmimi: row.Cmimi,
          Cmimi_Fiskal: row.Cmimi_Fiskal,
          Sasia_Ishte: row.Sasia_Ishte,
          Sasia_Deklaruar: row.Sasia_Deklaruar,
          Sasia_Gjetur: row.Sasia_Gjetur,
          Sasia_Shitje: row.Sasia_Shitje,
          Cmimi_Kosto: row.Cmimi_Kosto,
          Cmimi_Kosto_Manual: row.Cmimi_Kosto_Manual,
          PeshaKg: row.PeshaKg,
          SasiaPak: row.SasiaPak,
          Volumi: row.Volumi,
          Cmimi_Pa_TVSH: row.Cmimi_Pa_TVSH,
          TVSH: row.TVSH,
          Vlera: row.Vlera,
          Vlera_pa_TVSH: row.Vlera_pa_TVSH,
          Vlera_Fiskal: row.Vlera_Fiskal,
          Vlera_Kosto: row.Vlera_Kosto,
          Vlera_Kosto_Manual: row.Vlera_Kosto_Manual,
          Klasifikim1_ID: row.Klasifikim1_ID,
          Klasifikim2_ID: row.Klasifikim2_ID,
          Klasifikim3_ID: row.Klasifikim3_ID,
          Klasifikim4_ID: row.Klasifikim4_ID,
          Inserted: row.Inserted,
          Updated: row.Updated,
          Operator: row.Operator,
        };
        return item;
      });
    };
    try {
      showLoading();
      //  POST request i 1 per FATURE SHITJE
      const response = await mainAxios.post(`/inventar`, {
        Aktiv: null,
        Magazina_Kodi: state.Magazina_Kodi,
        Data: formatDate(state.Data, true),
        Kodi: Number(state.Kodi),
        Shenim: state.Shenim,
        Operator: String(username),
      });
      if (response.status === 201) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        const newEntryID = response.data.Id;
        showToast("Inventari u rregjistrua.", {
          severity: "success",
        });

        // POST request i 2 per DTL
        const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
        await mainAxios.post(`/inventar/dtl/bulk`, dtlDataArray);
        setCurrentAction(null);
        fetchFHyrje(1);
      }
    } catch (error) {
      console.error("Error in POST request:", error);
      showToast("Gabim gjatë regjistrimit të dokumnetit" + error.message, {
        severity: "error",
      });
    } finally {
      // fetchDtl();
      hideLoading();
    }
  };

  // Kur Klikohet butoni i "MODIFIKO"
  const handleModify = async () => {
    const getItemsForSecondRequest = (rows, newEntryID) => {
      return rows.map((row) => {
        let item = {
          Id: row.Id,
          Master_Id: newEntryID,
          Art_ID: row.Art_ID,
          Kodi: row.Kodi,
          Kodi1: row.Kodi1,
          Kodi2: row.Kodi2,
          BarKod: row.BarKod,
          Magazina_Kodi: row.Magazina_Kodi,
          Pershkrim: row.Pershkrim,
          Njesi_Kodi: row.Njesi_Kodi,
          Koeficient: row.Koeficient,
          Cmimi: row.Cmimi,
          Cmimi_Fiskal: row.Cmimi_Fiskal,
          Sasia_Ishte: row.Sasia_Ishte,
          Sasia_Deklaruar: row.Sasia_Deklaruar,
          Sasia_Gjetur: row.Sasia_Gjetur,
          Sasia_Shitje: row.Sasia_Shitje,
          Cmimi_Kosto: row.Cmimi_Kosto,
          Cmimi_Kosto_Manual: row.Cmimi_Kosto_Manual,
          PeshaKg: row.PeshaKg,
          SasiaPak: row.SasiaPak,
          Volumi: row.Volumi,
          Cmimi_Pa_TVSH: row.Cmimi_Pa_TVSH,
          TVSH: row.TVSH,
          Vlera: row.Vlera,
          Vlera_pa_TVSH: row.Vlera_pa_TVSH,
          Vlera_Fiskal: row.Vlera_Fiskal,
          Vlera_Kosto: row.Vlera_Kosto,
          Vlera_Kosto_Manual: row.Vlera_Kosto_Manual,
          Klasifikim1_ID: row.Klasifikim1_ID,
          Klasifikim2_ID: row.Klasifikim2_ID,
          Klasifikim3_ID: row.Klasifikim3_ID,
          Klasifikim4_ID: row.Klasifikim4_ID,
          Inserted: row.Inserted,
          Updated: row.Updated,
          Operator: row.Operator,
        };
        return item;
      });
    };
    try {
      showLoading();
      // Requesti i 1 PUT per Flete Hyrjen
      const response = await mainAxios.put(`/inventar/${currentId}`, {
        Aktiv: null,
        Magazina_Kodi: state.Magazina_Kodi,
        Data: formatDate(state.Data, true),
        Kodi: Number(state.Kodi),
        Shenim: state.Shenim,
        Operator: String(username),
      });
      if (response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        const newEntryID = response.data.Id;

        showToast("Inventari u modifikua!", {
          severity: "success",
        });

        //  PUT request i 2 per modifikimin e DTL
        const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
        await mainAxios.put(`/inventar/dtl/bulk`, dtlDataArray);
      } else {
        showToast("Gabim gjatë modifikimit të artikujve.", {
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error in POST request:", error);
      showToast("Gabim gjatë modifikimit të Inventarit.", {
        severity: "error",
      });
    } finally {
      fetchFHyrje(number);
      hideLoading();
      setCurrentAction(null);
    }
  };

  const handleMagazinaChange = (event, newValue) => {
    if (newValue) {
      updateMagazinaKodi(newValue);
    }
  };

  const updateMagazinaKodi = (newKodi) => {
    setState((prevState) => ({
      ...prevState,
      Magazina_Kodi: newKodi,
    }));
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const takeMonedha = monedhat.map((item) => item);

  const handleKursiType = (e) => {
    const value = e.target.value;
    const kursi = takeMonedha.find((item) => item.Kodi === value).Kursi;

    setState((state) => {
      return {
        ...state,
        Mon: value,
        Kursi: kursi,
      };
    });
  };

  const anulloFature = () => {
    let excludeData = ["Data"];
    let hasValue = Object.keys(state)?.find(
      (key) => !excludeData.includes(key) && state[key] !== ""
    );
    if (hasValue) setHidePupUp(true);
    setDeletedRowIds([]);
  };

  const button1Text = isMobile ? "" : "Shtim";
  const button2Text = isMobile ? "" : "Modifikim";
  const button3Text = isMobile ? "" : "Fshije";
  const button4Text = isMobile ? "" : "Anullim";
  const button5Text = isMobile ? "" : "Regjistro";

  const PaneliKryesor = (
    <PaneliTabeve
      fatureDisabled={fatureDisabled}
      fleteName={"Inventar"}
      fatureName={"Inventar"}
      popupContextKeyType={true}
      popupContextKey="Inventar"
      contexDelete={"Inventar"}
      onAddClick={onAddClick}
      onModifyClick={onModifyClick}
      // fetchLastNumberFature={fetchLastNumberFature}
      number={number}
      handleRegister={handleRegister}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      currentId={currentId}
      setRows={setRows}
      index={index}
      setIndex={setIndex}
      responseData={responseData}
      setResponseData={setResponseData}
      lastObject={lastObject}
      setLastObject={setLastObject}
      fetchFHyrje={fetchFHyrje}
      setHidePupUp={setHidePupUp}
      hidePupUp={hidePupUp}
      toggleTab={toggleTab}
      anulloFature={anulloFature}
      buttonClicked={buttonClicked}
      setButtonClicked={setButtonClicked}
      button1Text={button1Text}
      button1Icon={<PostAddIcon />}
      button2Text={button2Text}
      button2Icon={<ModeEditIcon />}
      button3Text={button3Text}
      button3Icon={<DeleteIcon />}
      button4Text={button4Text}
      button4Icon={<ClearIcon />}
      button5Text={button5Text}
      button5Icon={<AppRegistrationIcon />}
      isMobile={isMobile}
      setCurrentAction={setCurrentAction}
    />
  );

  const button6Text = isMobile ? "" : "Tjera";

  const PaneliDytesor = (
    <PaneliTab2
      includeButton6={true}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      toggleTab={toggleTab}
      button6Text={button6Text}
      button6Icon={<AutoAwesomeMotionIcon />}
      isMobile={isMobile}
    />
  );

  const TabiKryesor = (
    <MainTab
      fleteHyrje={true}
      formatDate={formatDate}
      includeDateField={true}
      includeKursiField={false}
      includeMonedhaSelect={false}
      includeKodiField={true}
      includeModalList={true}
      includeMagazina={true}
      disabled={disabled}
      state={state}
      handleMagazinaChange={handleMagazinaChange}
      magazina={magazina}
      handleChange={handleChange}
      handleKursiType={handleKursiType}
      numMag={numMag}
    />
  );

  const BtnPrint = (
    <PrintoPdfFleteHyrje className="print" state={state} rows={rows} />
  );

  const smallTabsKM = (
    <div>
      {/* tab4 */}
      <div className={"content active-content"}>
        <div className="grid grid-cols-2 gap-2">
          <TextField
            disabled={disabled}
            type="text"
            variant="outlined"
            label="Shenim"
            value={state?.Shenim || ""}
            onChange={(e) => handleChange("Shenim", e.target.value)}
            size="small"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={`mainDiv_ftsh ${!drawerOpen ? "drawer-closed" : ""}`}>
      {!isMobile && <SideBar drawerOpen={drawerOpen} />}
      <div className="right-column">
        <NavBar
          currentAction={currentAction}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
        />
        <div className="template">
          <div className="main-container p-2">
            <div className="top_panel_fsh">
              <Panel
                headerTemplate={
                  <Template
                    options={{
                      collapsed: isPanelToggleable,
                      onTogglerClick: () =>
                        setIsPanelToggleable(!isPanelToggleable),
                    }}
                    createdBy={state.Operator}
                    isMobile={isMobile}
                    fatureName="Inventar"
                    currentId={currentId}
                    nxtBtnDisable={nxtBtnDisable}
                    prvBtnDisable={prvBtnDisable}
                    shikoFaturen={shikoFaturen}
                    handleTestiChange={handleTestiChange}
                    columnsSF={columnsSF}
                    contextKey={"inventar"}
                    visible={visible}
                    setVisible={setVisible}
                    isOnline={isOnline}
                    gridKey="Inventar"
                  />
                }
                toggleable
                collapsed={isPanelToggleable}
                onToggle={(e) => setIsPanelToggleable(!isPanelToggleable)}
                style={{ height: "100%" }}
              >
                <div className="bg-white">
                  {!isMobile ? (
                    <>
                      <div className="paneli-tabeve">
                        <div className="paneli-tabeve1">{PaneliKryesor}</div>
                        <div className="paneli-tabeve2">{PaneliDytesor}</div>
                        <div className="paneli-fis-print">{BtnPrint}</div>
                      </div>
                      <div className={"below-tabs"}>
                        <div className="below-tabs1">{TabiKryesor}</div>
                        <div className="below-tabs2">{smallTabsKM}</div>
                        <div className="below-tabs3"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "5px",
                        }}
                      >
                        {PaneliKryesor}
                        {BtnPrint}
                      </div>
                      <div style={{ margin: "5px" }}>{TabiKryesor}</div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "5px",
                        }}
                      >
                        {PaneliDytesor}
                      </div>
                      <div
                        style={{
                          margin: 5,
                        }}
                      >
                        {smallTabsKM}
                      </div>
                    </>
                  )}
                </div>
              </Panel>
            </div>

            <div className="middle_fsh"></div>

            <div className="dataTable_fsh">
              <PrimeGrid
                deletedRowIds={deletedRowIds}
                setDeletedRowIds={setDeletedRowIds}
                gridKey="FleteHyrje"
                contextKey={"/inventar/dtl"}
                isEditable={true}
                columns={columns}
                defaultColumnsStorage={defaultColumnsStorage}
                setColumns={setColumns}
                disabled={disabled}
                rows={rows}
                setRows={setRows}
                calculateData={handleCalculateData}
                shtoArtikull={handleShtoArtikull}
                currentAction={currentAction}
                currentId={currentId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Inventar);
