import React, { useState, useRef } from "react";
import { Checkbox } from "primereact/checkbox";
import AddIcon from "@mui/icons-material/Add";
import { Divider, TextField, Typography } from "@mui/material";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import mainAxios from "../../../services/axios";
import { getSkemaLlogari } from "../../../store/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import SmallButtonGroup from "../../fature-blerje/Function/SmallButton";
import { useToast } from "../../../components/context/ToastContext";

const SkemaKontabilizimit = ({ open, onClose }) => {
  const defaultState = {
    Inventar: "",
    Inventar_Pershkrim: "",
    Kodi: "",
    Pershkrim: "",
    Blerje: "",
    Blerje_Pershkrim: "",
    Shitje: "",
    Shitje_Pershkrim: "",
    NdrGjendje: "",
    NrdGjendje_Pershkrim: "",
    Amortizim: "",
  };
  // skemaLlogari redux
  const skemaLlogariRedux = useSelector(
    (state) => state.globalSlice.skemaLlogari
  );
  const skemaLlogari = skemaLlogariRedux;

  const numSkemaLlogari = `${skemaLlogari ? skemaLlogari.length : 0} Rekorde`;

  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState(defaultState);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProduct1, setSelectedProduct1] = useState(null);
  const [check] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const showToast = useToast();

  const dispatch = useDispatch();
  const submitHanlder = async () => {
    if (!state.Kodi.trim() || !state.Pershkrim.trim()) {
      showToast(
        "Plotesoni fushat e kerkuara: " +
          (!state.Kodi.trim() ? "Kodi, " : "") +
          (!state.Pershkrim.trim() ? "Pershkrim, " : ""),
        {
          severity: "warn",
        }
      );
      return;
    }
    try {
      const responseTar = await mainAxios.post(`/skema/kontabilizimit`, {
        Pershkrim: state.Pershkrim,
        Kodi: state.Kodi,
        Inventar: state.Inventar,
        Inventar_Pershkrim: state.Inventar_Pershkrim,
        Blerje: state.Blerje,
        Blerje_Pershkrim: state.Blerje_Pershkrim,
        Shitje: state.Shitje,
        Shitje_Pershkrim: state.Shitje_Pershkrim,
        NdrGjendje: state.NdrGjendje,
        NrdGjendje_Pershkrim: state.NrdGjendje_Pershkrim,
        Amortizim: state.Amortizim,
      });

      if (responseTar.status === 201) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getSkemaLlogari("/skema/kontabilizimit"));

        showToast("Skema Kontabilizimit u regjistrua", {
          severity: "success",
        });
      }
    } catch (error) {
      showToast("Nuk u regjistrua." + error.message, {
        severity: "error",
      });
    }
  };

  const deleteMethod = async (selectedRowId) => {
    try {
      const resDelete = await mainAxios.delete(
        `/skema/kontabilizimit/${selectedRowId}`
      );
      if (resDelete.status === 200) {
        setSelectedRowId(null);

        showToast("U fshi", {
          severity: "info",
        });
        dispatch(getSkemaLlogari("/skema/kontabilizimit"));
      }
    } catch (err) {
      console.error(err);

      showToast("Nuk mund te fshihet.", {
        severity: "error",
      });
    }
  };

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const [columns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Inventar", title: "Inventar" },
  ]);

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case columns:
        if (isPositiveInteger(newValue)) rowData[field] = newValue;
        else event.preventDefault();
        break;

      default:
        if (newValue.length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }
  };

  const cellEditor = (options, col) => {
    const column = options?.column;
    if (column?.props?.editColumn === false) {
      return <Column key={column.field} colSpan={1}></Column>;
    }

    return textEditor(options, col);
  };

  const handleRowClick = (rowData) => {
    setState({
      ...defaultState,
      Kodi: rowData.Kodi,
      Pershkrim: rowData.Pershkrim,
      Inventar: rowData.Inventar,
      Inventar_Pershkrim: rowData.Inventar_Pershkrim,
      Blerje: rowData.Blerje,
      Blerje_Pershkrim: rowData.Blerje_Pershkrim,
      Shitje: rowData.Shitje,
      Shitje_Pershkrim: rowData.Shitje_Pershkrim,
      NdrGjendje: rowData.NdrGjendje,
      NrdGjendje_Pershkrim: rowData.NrdGjendje_Pershkrim,
      Amortizim: rowData.Amortizim,
    });
    setSelectedRowId(rowData.Id);
    setDisabled(false);
  };

  const textEditor = (options, col) => {
    <InputText
      disabled={() => setDisabled(true)}
      className="cursor-pointer inp"
      type={col?.fieldType ?? "text"}
      value={options.value}
      onChange={(e) => options.editorCallback(e.target.value)}
    />;
  };

  const cm = useRef(null);

  const dynamicColumns = columns.map((col, i) => {
    return check ? (
      <Column
        editor={(options) => cellEditor(options, col)}
        onCellEditComplete={onCellEditComplete}
        key={col.field}
        field={col.field}
        header={col.title}
      />
    ) : (
      <Column key={col.field} field={col.field} header={col.title} />
    );
  });

  return (
    <div>
      <form>
        <Dialog
          header="Skema e Kontabilizimit"
          visible={open}
          onHide={() => onClose()}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "24.5vw" }}
        >
          <div className=" mt-2">
            <SmallButtonGroup
              fromSkemaLlogari={true}
              buttonClicked={buttonClicked}
              deleteMethod={deleteMethod}
              setButtonClicked={setButtonClicked}
              setDisabled={setDisabled}
              disabled={disabled}
              setState={setState}
              defaultState={defaultState}
              submitHandler={submitHanlder}
              selectedRowId={selectedRowId}
            />
          </div>

          <div className="border flex flex-col p-2 relative mt-3">
            {/* <span className='absolute z-20' style={{top:-15,color:"#1971c2",backgroundColor:"white",fontWeight:500}}>Skema</span> */}
            <Divider sx={{ width: "100%", marginY: 1 }} />
            <Typography>Skema</Typography>
            <TextField
              disabled={disabled}
              type="text"
              variant="outlined"
              label="Kodi"
              inputProps={{ maxLength: "10" }}
              size="small"
              value={state.Kodi || ""}
              onChange={(e) => handleChange("Kodi", e.target.value)}
              className="mt-2 w-7/12"
            />

            <TextField
              disabled={disabled}
              variant="outlined"
              label="Pershkrim"
              type="text"
              value={state.Pershkrim || ""}
              onChange={(e) => handleChange("Pershkrim", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />
            <Divider sx={{ width: "100%", marginY: 1 }} />
            <Typography>Llogarite</Typography>
            <div className="flex" style={{ justifyContent: "space-between" }}>
              <TextField
                disabled={disabled}
                type="text"
                variant="outlined"
                label="Inventar"
                size="small"
                value={state.Inventar || ""}
                onChange={(e) => handleChange("Inventar", e.target.value)}
                className="mt-2 w-4/12"
              />
              <TextField
                disabled={disabled}
                type="text"
                variant="outlined"
                label="Inventar_Pershkrim"
                size="small"
                value={state.Inventar_Pershkrim || ""}
                onChange={(e) =>
                  handleChange("Inventar_Pershkrim", e.target.value)
                }
                className="mt-2 w-7/12"
              />
            </div>
            <div className="flex" style={{ justifyContent: "space-between" }}>
              <TextField
                disabled={disabled}
                type="text"
                variant="outlined"
                label="Blerje"
                size="small"
                value={state.Blerje || ""}
                onChange={(e) => handleChange("Blerje", e.target.value)}
                className="mt-2 w-4/12"
              />
              <TextField
                disabled={disabled}
                type="text"
                variant="outlined"
                label="Blerje_Pershkrim"
                size="small"
                value={state.Blerje_Pershkrim || ""}
                onChange={(e) =>
                  handleChange("Blerje_Pershkrim", e.target.value)
                }
                className="mt-2 w-7/12"
              />
            </div>
            <div className="flex" style={{ justifyContent: "space-between" }}>
              <TextField
                disabled={disabled}
                type="text"
                variant="outlined"
                label="Shitje"
                size="small"
                value={state.Shitje || ""}
                onChange={(e) => handleChange("Shitje", e.target.value)}
                className="mt-2 w-4/12"
              />
              <TextField
                disabled={disabled}
                type="text"
                variant="outlined"
                label="Shitje_Pershkrim"
                size="small"
                value={state.Shitje_Pershkrim || ""}
                onChange={(e) =>
                  handleChange("Shitje_Pershkrim", e.target.value)
                }
                className="mt-2 w-7/12"
              />
            </div>
            <div className="flex" style={{ justifyContent: "space-between" }}>
              <TextField
                disabled={disabled}
                type="text"
                variant="outlined"
                label="NdrGjendje"
                size="small"
                value={state.NdrGjendje || ""}
                onChange={(e) => handleChange("NdrGjendje", e.target.value)}
                className="mt-2 w-4/12"
              />
              <TextField
                disabled={disabled}
                type="text"
                variant="outlined"
                label="NrdGjendje_Pershkrim"
                size="small"
                value={state.NrdGjendje_Pershkrim || ""}
                onChange={(e) =>
                  handleChange("NrdGjendje_Pershkrim", e.target.value)
                }
                className="mt-2 w-7/12"
              />
            </div>
            <div className="flex" style={{ justifyContent: "space-between" }}>
              <TextField
                disabled={disabled}
                type="text"
                variant="outlined"
                label="Amortizim"
                size="small"
                value={state.Amortizim || ""}
                onChange={(e) => handleChange("Amortizim", e.target.value)}
                className="mt-2 w-4/12"
              />{" "}
              {/* <TextField
                disabled={disabled}
                type="text"
                variant="outlined"
                label="Amortizim"
                size="small"
                value={state.Amortizim || ""}
                onChange={(e) => handleChange("Amortizim", e.target.value)}
                className="mt-2 w-7/12"
              /> */}
            </div>
            <div className="border p-2 flex flex-col mt-2">
              <div className="flex items-center">
                <DataTable
                  style={{ width: "100%" }}
                  editMode="cell"
                  scrollable
                  selectionMode="single"
                  selection={selectedProduct1}
                  onSelectionChange={() => setSelectedProduct1(defaultState)}
                  size="small"
                  scrollHeight="10rem"
                  value={skemaLlogari}
                  responsiveLayout="scroll"
                  contextMenuSelection={selectedProduct}
                  contextselection={selectedProduct}
                  onContextMenuSelectionChange={(e) =>
                    setSelectedProduct(defaultState)
                  }
                  onContextMenu={(e) => cm.current.show(e.originalEvent)}
                  onRowClick={(e) => handleRowClick(e.data)}
                >
                  {dynamicColumns}
                </DataTable>
              </div>
            </div>
          </div>
          <span>
            <b>{numSkemaLlogari}</b>
          </span>
        </Dialog>
      </form>
    </div>
  );
};

export default SkemaKontabilizimit;
