import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";

const ReusableTabPanel = ({ tabs, gridKey, onSelectionChange, style }) => {
  return (
    <div className="card">
      <TabView>
        {tabs.map((tab, index) => (
          <TabPanel key={index} header={tab.header}>
            <div className={tab.className || "card_artikuj"}>
              <DataTable
                size={tab.size || "small"}
                gridKey={gridKey || tab.gridKey}
                header={tab.headerDataTable}
                selectionMode={tab.selectionMode || "single"}
                onSelectionChange={onSelectionChange}
                scrollable
                showGridlines
                value={tab.value}
                responsiveLayout={tab.responsiveLayout || "scroll"}
                style={style || tab.style}
              >
                {tab.dynamicColumns}
              </DataTable>
            </div>
            {tab.footer && <div>{tab.footer}</div>}
          </TabPanel>
        ))}
      </TabView>
    </div>
  );
};

export default ReusableTabPanel;
