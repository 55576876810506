import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import useStorage from "../../hooks/useStorage";
import { useDispatch, useSelector } from "react-redux";
import { getArtikujPag } from "../../store/globalSlice";
import { InputText } from "primereact/inputtext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "./ModalList.scss";
import mainAxios from "../../services/axios";
import { useToast } from "../context/ToastContext";
import Header from "./Header/HeaderSherbime";
import HeaderKlient from "./Header/HeaderKlient";
import HeaderFurnitor from "./Header/HeaderFurnitor";
import HeaderAgjent from "./Header/HeaderAgjent";
import HeaderPunonjes from "./Header/HeaderPunonjes";
import HeaderArka from "./Header/HeaderArka";
import HeaderBanka from "./Header/HeaderBanka";
import ReusableTabPanel from "./TabPanel/TabPanel";

// Utility function to create mapped rows
const createMappedRows = (data, type) =>
  data.map((item) => ({
    ...item,
    Tipi: type,
  }));

// Column definitions
const columnDefinitions = {
  default: [
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
  ],
  artikuj: [
    { field: "Kodi", title: "Kodi" },
    { field: "Barkod", title: "Barkod" },
    { field: "Njesi_Kodi", title: "Njesi_Kodi" },
    { field: "Cmimi", title: "Cmimi" },
  ],
  sherbim: [
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Cmimi", title: "Cmimi" },
  ],
  klient: [
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Qyteti_Kodi", title: "Qyteti" },
  ],
};

const ModalList = ({
  shtoArtikull,
  gridKey,
  showHideColumns = true,
  disabled,
  rows,
}) => {
  const showToast = useToast();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [searchTermLlogari, setSearchTermLlogari] = useState("");
  const [searchTermSherbime, setSearchTermSherbime] = useState("");
  const [searchTermKlient, setSearchTermKlient] = useState("");
  const [searchTermFurnitor, setSearchTermFurnitor] = useState("");
  const [searchTermArka, setSearchTermArka] = useState("");
  const [searchTermBanka, setSearchTermBanka] = useState("");
  const [searchTermAgjent, setSearchTermAgjent] = useState("");
  const [searchTermPunonjes, setSearchTermPunonjes] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numPerPage] = useState(100);
  const [disableBtnNext, setDisableBtnNext] = useState(false);
  const [disableBtnPrev, setDisableBtnPrev] = useState(false);
  const [dataArt, setDataArt] = useState([]);

  // Redux selectors and mapped rows
  const reduxData = useSelector((state) => ({
    sherbime: createMappedRows(state.globalSlice.sherbime, "SH"),
    klient: createMappedRows(state.globalSlice.klienti, "KL"),
    furnitor: createMappedRows(state.globalSlice.furnitor, "FU"),
    arka: createMappedRows(state.globalSlice.arkaList, "A"),
    banka: createMappedRows(state.globalSlice.bankaList, "B"),
    agjent: createMappedRows(state.globalSlice.agjenti, "AG"),
    punonjes: createMappedRows(state.globalSlice.punonjes, "PU"),
    llogari: createMappedRows(state.globalSlice.llogari, "LL"),
    artikuj: createMappedRows(state.globalSlice.artikujPag, "AR"),
  }));

  const numArtikuj = reduxData.artikuj.length;

  useEffect(() => {
    if (visible)
      dispatch(
        getArtikujPag(
          `/artikull/pag?page=${currentPage}&per_page=${numPerPage}`
        )
      );
  }, [dispatch, currentPage, numPerPage]);

  useEffect(() => {
    if (numArtikuj === 0) {
      dispatch(
        getArtikujPag(
          `/artikull/pag?page=${currentPage}&per_page=${numPerPage}`
        )
      );
    } else {
      setDataArt(reduxData.artikuj);
    }
  }, [dispatch, currentPage, numPerPage]);

  useEffect(() => {
    if (currentPage === 1) {
      setDisableBtnPrev(true);
    } else {
      setDisableBtnPrev(false);
    }

    if (numArtikuj < numPerPage) {
      setDisableBtnNext(true);
    } else {
      setDisableBtnNext(false);
    }
  }, [currentPage, numPerPage, numArtikuj]);

  // Function to generate a unique Id between 0 and 999
  const generateUniqueId = () => {
    return Math.floor(Math.random() * 1000);
  };

  const onSelectionChange = (selected) => {
    const selectedValue = selected.value;
    // Check if selectedValue.Kodi exists in rows
    const exists = rows.some((item) => item.Kodi === selectedValue.Kodi);

    if (exists) {
      if (window.confirm("Artikulli ekziston. Dëshironi ta shtoni?")) {
        // Generate a new Id for selectedValue
        const newId = generateUniqueId();

        // Assign the new Id to selectedValue
        selectedValue.Id = newId;
        shtoArtikull(selectedValue, gridKey);

        // Close the dialog
        setVisible(false);
      } else {
        // User clicked "No" or cancelled
        return;
      }
    } else {
      // Generate a new Id for selectedValue
      const newId = generateUniqueId();

      // Assign the new Id to selectedValue
      selectedValue.Id = newId;
      shtoArtikull(selectedValue, gridKey);

      // Close the dialog
      setVisible(false);
    }
  };

  // Selected columns with useStorage
  const [selectedColumns, setSelectedColumns] = useStorage(
    columnDefinitions.artikuj,
    "kolonatEartikujve" + gridKey
  );

  const [selectedColumnsLlogari, setSelectedColumnsLlogari] = useStorage(
    columnDefinitions.default,
    "kolonatLlogari" + gridKey
  );

  const [selectedColumnsSherbime, setSelectedColumnsSherbime] = useStorage(
    columnDefinitions.sherbim,
    "kolonatSherbime" + gridKey
  );

  const [selectedColumnsKlient, setSelectedColumnsKlient] = useStorage(
    columnDefinitions.klient,
    "kolonatKlient" + gridKey
  );

  const [selectedColumnsFurnitor, setSelectedColumnsFurnitor] = useStorage(
    columnDefinitions.klient,
    "kolonatFurnitor" + gridKey
  );

  const [selectedColumnsArka, setSelectedColumnsArka] = useStorage(
    columnDefinitions.default,
    "kolonatArka" + gridKey
  );

  const [selectedColumnsBanka, setSelectedColumnsBanka] = useStorage(
    columnDefinitions.default,
    "kolonatBanka" + gridKey
  );

  const [selectedColumnsAgjent, setSelectedColumnsAgjent] = useStorage(
    columnDefinitions.default,
    "kolonatAgjent" + gridKey
  );

  const [selectedColumnsPunonjes, setSelectedColumnsPunonjes] = useStorage(
    columnDefinitions.default,
    "kolonatPunonjes" + gridKey
  );

  // Generate dynamic columns based on selected values
  const dynamicColumns = selectedColumns.map((col) => (
    <Column key={col.field} field={col.field} header={col.title} />
  ));

  const dynamicColumnsLlogari = selectedColumnsLlogari.map((col) => (
    <Column key={col.field} field={col.field} header={col.title} />
  ));

  const dynamicColumnsSherbime = selectedColumnsSherbime.map((col) => (
    <Column key={col.field} field={col.field} header={col.title} />
  ));

  const dynamicColumnsKlient = selectedColumnsKlient.map((col) => (
    <Column key={col.field} field={col.field} header={col.title} />
  ));

  const dynamicColumnsFurnitor = selectedColumnsFurnitor.map((col) => (
    <Column key={col.field} field={col.field} header={col.title} />
  ));

  const dynamicColumnsArka = selectedColumnsArka.map((col) => (
    <Column key={col.field} field={col.field} header={col.title} />
  ));

  const dynamicColumnsBanka = selectedColumnsBanka.map((col) => (
    <Column key={col.field} field={col.field} header={col.title} />
  ));

  const dynamicColumnsAgjent = selectedColumnsAgjent.map((col) => (
    <Column key={col.field} field={col.field} header={col.title} />
  ));

  const dynamicColumnsPunonjes = selectedColumnsPunonjes.map((col) => (
    <Column key={col.field} field={col.field} header={col.title} />
  ));

  // Column toggle function with type
  const onColumnToggle = (event, type) => {
    const selectedCols = event.value;

    if (type === "columns") {
      const updatedSelectedColumns = columnDefinitions.artikuj.filter((col) =>
        selectedCols.some((sCol) => sCol.field === col.field)
      );
      setSelectedColumns(updatedSelectedColumns);
    } else if (type === "llogari") {
      const updatedSelectedColumnsLlogari = columnDefinitions.default.filter(
        (col) => selectedCols.some((sCol) => sCol.field === col.field)
      );
      setSelectedColumnsLlogari(updatedSelectedColumnsLlogari);
    } else if (type === "sherbime") {
      const updatedSelectedColumnsSherbime = columnDefinitions.sherbim.filter(
        (col) => selectedCols.some((sCol) => sCol.field === col.field)
      );
      setSelectedColumnsSherbime(updatedSelectedColumnsSherbime);
    } else if (type === "klient") {
      const updatedSelectedColumnsKlient = columnDefinitions.klient.filter(
        (col) => selectedCols.some((sCol) => sCol.field === col.field)
      );
      setSelectedColumnsKlient(updatedSelectedColumnsKlient);
    } else if (type === "furnitor") {
      const updatedSelectedColumnsFurnitor = columnDefinitions.klient.filter(
        (col) => selectedCols.some((sCol) => sCol.field === col.field)
      );
      setSelectedColumnsFurnitor(updatedSelectedColumnsFurnitor);
    } else if (type === "arka") {
      const updatedSelectedColumnsArka = columnDefinitions.default.filter(
        (col) => selectedCols.some((sCol) => sCol.field === col.field)
      );
      setSelectedColumnsArka(updatedSelectedColumnsArka);
    } else if (type === "banka") {
      const updatedSelectedColumnsBanka = columnDefinitions.default.filter(
        (col) => selectedCols.some((sCol) => sCol.field === col.field)
      );
      setSelectedColumnsBanka(updatedSelectedColumnsBanka);
    } else if (type === "agjent") {
      const updatedSelectedColumnsAgjent = columnDefinitions.default.filter(
        (col) => selectedCols.some((sCol) => sCol.field === col.field)
      );
      setSelectedColumnsAgjent(updatedSelectedColumnsAgjent);
    } else if (type === "punonjes") {
      const updatedSelectedColumnsPunonjes = columnDefinitions.default.filter(
        (col) => selectedCols.some((sCol) => sCol.field === col.field)
      );
      setSelectedColumnsPunonjes(updatedSelectedColumnsPunonjes);
    }
  };

  const filterData = (data, searchTerm, fieldNames) => {
    if (!searchTerm) return data;
    return data.filter((item) => {
      return fieldNames.some((fieldName) => {
        const fieldValue = item[fieldName];
        return (
          fieldValue != null &&
          fieldValue.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchInputChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    // If the search term is empty, reset data to the original `artikuj` data
    if (newSearchTerm.length === 0) {
      setDataArt(reduxData.artikuj);
    }
  };

  const handleSearchClick = async () => {
    if (searchTerm.length === 0) {
      return;
    }
    try {
      const response = await mainAxios.get(`/art/search/${searchTerm}`);
      const rowsArtikuj = response.data.map((item) => ({
        ...item,
        Tipi: "AR",
      }));
      setDataArt(rowsArtikuj);
    } catch (error) {
      console.error(error);

      showToast("Artikulli nuk u gjend", {
        severity: "error",
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleSearchClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchTerm]);

  const header = (
    <>
      {showHideColumns && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{ width: "20rem", display: "flex", alignItems: "center" }}
            className="p-input-icon-right"
          >
            <i className="pi pi-search" />
            <InputText
              placeholder="Search in Artikuj..."
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
            <Button
              type="button"
              variant="contained"
              onClick={handleSearchClick}
              style={{
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Kerko
            </Button>
          </span>

          <MultiSelect
            className="selectArtikuj"
            value={selectedColumns}
            options={columnDefinitions.artikuj}
            optionLabel="title"
            onChange={(e) => onColumnToggle(e, "columns")} // Specify type "columns"
            style={{ width: "3em", height: "2em" }}
          />
        </div>
      )}
    </>
  );

  const headerLlogari = (
    <>
      {showHideColumns && (
        <Header
          placeholder="Search in Llogari..."
          searchTerm={searchTermLlogari}
          setSearchTerm={setSearchTermLlogari}
          columns={columnDefinitions.default}
          onColumnToggle={onColumnToggle}
          selectedColumns={selectedColumnsLlogari}
          additionalProps={{ className: "selectLlogari", type: "llogari" }}
        />
      )}
    </>
  );

  const headerSherbime = (
    <>
      {showHideColumns && (
        <Header
          placeholder="Search in Sherbime..."
          searchTerm={searchTermSherbime}
          setSearchTerm={setSearchTermSherbime}
          columns={columnDefinitions.sherbim}
          onColumnToggle={onColumnToggle}
          selectedColumns={selectedColumnsSherbime}
          additionalProps={{ className: "selectSherbime", type: "sherbime" }}
          showDialog
          dialogData={reduxData.sherbime}
        />
      )}
    </>
  );

  const headerKlient = (
    <>
      {showHideColumns && (
        <HeaderKlient
          searchTermSherbime={searchTermKlient}
          setSearchTermSherbime={setSearchTermKlient}
          sherbime={reduxData.klient}
          columnsSherbime={columnDefinitions.klient}
          onColumnToggle={onColumnToggle}
          selectedColumnsSherbime={selectedColumnsKlient}
        />
      )}
    </>
  );
  const headerFurnitor = (
    <>
      {showHideColumns && (
        <HeaderFurnitor
          searchTermSherbime={searchTermFurnitor}
          setSearchTermSherbime={setSearchTermFurnitor}
          sherbime={reduxData.furnitor}
          columnsSherbime={columnDefinitions.klient}
          onColumnToggle={onColumnToggle}
          selectedColumnsSherbime={selectedColumnsFurnitor}
        />
      )}
    </>
  );
  const headerAgjent = (
    <>
      {showHideColumns && (
        <HeaderAgjent
          searchTermSherbime={searchTermAgjent}
          setSearchTermSherbime={setSearchTermAgjent}
          sherbime={reduxData.agjent}
          columnsSherbime={columnDefinitions.default}
          onColumnToggle={onColumnToggle}
          selectedColumnsSherbime={selectedColumnsAgjent}
        />
      )}
    </>
  );
  const headerPunonjes = (
    <>
      {showHideColumns && (
        <HeaderPunonjes
          searchTermSherbime={searchTermPunonjes}
          setSearchTermSherbime={setSearchTermPunonjes}
          sherbime={reduxData.punonjes}
          columnsSherbime={columnDefinitions.default}
          onColumnToggle={onColumnToggle}
          selectedColumnsSherbime={selectedColumnsPunonjes}
        />
      )}
    </>
  );
  const headerArka = (
    <>
      {showHideColumns && (
        <HeaderArka
          searchTermSherbime={searchTermArka}
          setSearchTermSherbime={setSearchTermArka}
          sherbime={reduxData.arka}
          columnsSherbime={columnDefinitions.default}
          onColumnToggle={onColumnToggle}
          selectedColumnsSherbime={selectedColumnsArka}
        />
      )}
    </>
  );

  const headerBanka = (
    <>
      {showHideColumns && (
        <HeaderBanka
          searchTermSherbime={searchTermBanka}
          setSearchTermSherbime={setSearchTermBanka}
          sherbime={reduxData.banka}
          columnsSherbime={columnDefinitions.default}
          onColumnToggle={onColumnToggle}
          selectedColumnsSherbime={selectedColumnsBanka}
        />
      )}
    </>
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1200);
    };
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  const fontOverlay = {
    fontSize: "11px",
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevClick = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const footerBtn = (
    <>
      <div>
        <Button
          variant="contained"
          onClick={handlePrevClick}
          disabled={disableBtnPrev}
        >
          Prev
        </Button>

        <Button
          style={{ marginLeft: 5 }}
          variant="contained"
          onClick={handleNextClick}
          disabled={disableBtnNext}
        >
          Next
        </Button>
      </div>
    </>
  );

  return (
    <>
      <div style={{ display: "flex" }}>
        <Button
          disabled={disabled}
          type="button"
          style={{ height: "2rem" }}
          variant="contained"
          onClick={() => setVisible(true)}
        >
          {!isMobile ? (
            <>
              <AddCircleIcon /> Shto
            </>
          ) : (
            <AddCircleIcon />
          )}
        </Button>
      </div>
      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        style={
          isMobile
            ? { width: "90%", height: "80%" }
            : { width: "40%", height: "70%" }
        }
        className="overlaypanel-demo"
      >
        {gridKey === "FleteDalje" || gridKey === "FleteHyrje" ? (
          <ReusableTabPanel
            tabs={[
              {
                header: "Artikuj",
                gridKey: "ArtikujListe",
                headerDataTable: header,
                value: dataArt,
                dynamicColumns: dynamicColumns,
                footer: footerBtn,
              },
            ]}
            gridKey={gridKey}
            onSelectionChange={onSelectionChange}
            style={{ fontOverlay }}
          />
        ) : null}
        {gridKey === "FatureShitje" || gridKey === "FatureBlerje" ? (
          <ReusableTabPanel
            tabs={[
              {
                header: "Artikuj",
                gridKey: "ArtikujListe",
                headerDataTable: header,
                value: dataArt,
                dynamicColumns: dynamicColumns,
                footer: footerBtn,
              },
              {
                header: "Llogari",
                gridKey: "Llogari",
                headerDataTable: headerLlogari,
                value: filterData(reduxData.llogari, searchTermLlogari, [
                  "Pershkrim",
                  "Kodi",
                ]),
                dynamicColumns: dynamicColumnsLlogari,
              },
              {
                header: "Sherbime",
                gridKey: "Sherbime",
                headerDataTable: headerSherbime,
                value: filterData(reduxData.sherbime, searchTermSherbime, [
                  "Pershkrim",
                  "Kodi",
                ]),
                dynamicColumns: dynamicColumnsSherbime,
              },
            ]}
            gridKey={gridKey}
            onSelectionChange={onSelectionChange}
            style={{ fontOverlay }}
          />
        ) : (
          <ReusableTabPanel
            tabs={[
              {
                header: "Llogari",
                gridKey: "Llogari",
                headerDataTable: headerLlogari,
                value: filterData(reduxData.llogari, searchTermLlogari, [
                  "Pershkrim",
                  "Kodi",
                ]),
                dynamicColumns: dynamicColumnsLlogari,
              },
              {
                header: "Sherbime",
                gridKey: "Sherbime",
                headerDataTable: headerSherbime,
                value: filterData(reduxData.sherbime, searchTermSherbime, [
                  "Pershkrim",
                  "Kodi",
                ]),
                dynamicColumns: dynamicColumnsSherbime,
              },
              {
                header: "Klienti",
                gridKey: "Klienti",
                headerDataTable: headerKlient,
                value: filterData(reduxData.klient, searchTermKlient, [
                  "Pershkrim",
                  "Kodi",
                ]),
                dynamicColumns: dynamicColumnsKlient,
              },
              {
                header: "Furnitor",
                gridKey: "Furnitor",
                headerDataTable: headerFurnitor,
                value: filterData(reduxData.furnitor, searchTermFurnitor, [
                  "Pershkrim",
                  "Kodi",
                ]),
                dynamicColumns: dynamicColumnsFurnitor,
              },
              {
                header: "Arka",
                gridKey: "Arka",
                headerDataTable: headerArka,
                value: filterData(reduxData.arka, searchTermArka, [
                  "Pershkrim",
                  "Kodi",
                ]),
                dynamicColumns: dynamicColumnsArka,
              },
              {
                header: "Banka",
                gridKey: "Banka",
                headerDataTable: headerBanka,
                value: filterData(reduxData.banka, searchTermBanka, [
                  "Pershkrim",
                  "Kodi",
                ]),
                dynamicColumns: dynamicColumnsBanka,
              },
              {
                header: "Agjent",
                gridKey: "Agjent",
                headerDataTable: headerAgjent,
                value: filterData(reduxData.agjent, searchTermAgjent, [
                  "Pershkrim",
                  "Kodi",
                ]),
                dynamicColumns: dynamicColumnsAgjent,
              },
              {
                header: "Punonjes",
                gridKey: "Punonjes",
                headerDataTable: headerPunonjes,
                value: filterData(reduxData.punonjes, searchTermPunonjes, [
                  "Pershkrim",
                  "Kodi",
                ]),
                dynamicColumns: dynamicColumnsPunonjes,
              },
            ]}
            gridKey={gridKey}
            onSelectionChange={onSelectionChange}
            style={{ fontOverlay }}
          />
        )}
      </Dialog>
    </>
  );
};

export default ModalList;
