import React, { useState } from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { buildTree } from "./buildTree";
import { Checkbox } from "primereact/checkbox"; // Import Checkbox
import "./Llogari.scss";

export default function BasicDemo({ llogari, handleRowClick }) {
  const [nodes, setNodes] = useState(buildTree(llogari));

  // Handle checkbox change
  const onCheckboxChange = (nodeKey, checked) => {
    const updatedNodes = nodes.map((node) =>
      updateNode(node, nodeKey, checked)
    );
    setNodes(updatedNodes);
  };

  // Recursive function to update checkbox state in tree
  const updateNode = (node, nodeKey, checked) => {
    if (node.key === nodeKey) {
      return { ...node, data: { ...node.data, Veprime: checked } };
    }
    if (node.children) {
      return {
        ...node,
        children: node.children.map((child) =>
          updateNode(child, nodeKey, checked)
        ),
      };
    }
    return node;
  };

  // Checkbox template for the "Veprime" column
  const checkboxTemplate = (node) => {
    return (
      <Checkbox
        checked={!!node.data.Veprime} // Ensure it's always true/false
        onChange={(e) => onCheckboxChange(node.key, e.checked)}
      />
    );
  };

  // Handle row click
  const onRowClick = (e) => {
    handleRowClick(e.node.data); // Pass the clicked row's data to the parent component
  };

  return (
    <div
      className="card_artikuj1"
      style={{ height: "67%", maxHeight: "68%", overflow: "auto" }}
    >
      <TreeTable
        value={nodes}
        tableStyle={{ minWidth: "50rem", color: "#000" }}
        className="custom-treetable"
        scrollable={true}
        scrollHeight="450px"
        onRowClick={onRowClick}
      >
        <Column field="Kodi" header="Llogari" expander></Column>
        <Column field="Pershkrim" header="Pershkrim"></Column>
        {/* <Column field="Origjina" header="Origjina"></Column> */}
        <Column field="Tarifa_Kodi" header="Tarifa"></Column>
        <Column field="AP" header="A/P"></Column>
        <Column header="Veprime" body={checkboxTemplate}></Column>
      </TreeTable>
    </div>
  );
}
