const shtoArtikull = (artikull, rows, setRows, state) => {
  if (!artikull) return;

  // Calculate the initial item
  const newItem = {
    ...artikull,
    Kredi: 0,
    Debi: 0,
    Mon: null,
    Kursi: 1,
    NrLL: artikull.NrLL,
    Vlera: 0,
    Total: 0,
  };

  // Calculate the new item data
  const calculatedItem = calculateData({ NrLL: newItem.NrLL }, newItem, state);
  // setRows((rows) => [calculatedItem, ...rows]);
  setRows((rows) => [...rows, calculatedItem]);
};

const calculateData = (field, item) => {
  let { Kursi, Debi, Kredi, Vlera, Total } = item;

  const [[cell, cellVal]] = Object.entries(field);

  try {
    let KrediPrv = Number(cell === "Kredi" ? cellVal : Kredi);
    let DebiPrv = Number(cell === "Debi" ? cellVal : Debi);
    let KursiPrv = Number(cell === "Kursi" ? cellVal : Kursi);

    if (cell === "Kredi") {
      Kredi = cellVal;
      Debi = 0;
      Vlera = (KursiPrv * -KrediPrv).toFixed(2);
    }
    if (cell === "Debi") {
      Debi = cellVal;
      Kredi = 0;
      Vlera = (KursiPrv * DebiPrv).toFixed(2);
    }
    if (cell === "Kursi") {
      Kursi = cellVal;
    }
    Total = Vlera * KursiPrv;

    return {
      ...item,
      Debi,
      Kredi,
      Kursi,
      Total,
    };
  } catch (error) {
    Vlera = 0;
    return item;
  }
};

export { shtoArtikull, calculateData };
