import mainAxios from "../../../../services/axios";
import { formatDate } from "../../../fatureShitje/Function/formatDate";

// Kur Klikohet butoni i "SHTIM"
const handleAdd = async (
  showLoading,
  state,
  username,
  setState,
  defaultState,
  setDisabled,
  setButtonClicked,
  setSearchString,
  showToast,
  rows,
  fetchFShitje,
  setCurrentAction,
  hideLoading
) => {
  const getItemsForSecondRequest = (rows, newEntryID) => {
    return rows.map((row) => {
      let item = {
        Kodi: row.Kodi,
        Tipi: row.Tipi,
        Master_Id: newEntryID,
        Shenim: state.Shenim,
        Pershkrim: row.Pershkrim,
        Mon: row.Mon,
        Kursi: row.Kursi,
        NrLL: row.NrLL,
        Kredi: row.Kredi,
        Debi: row.Debi,
        Punonjes_Id: row.Punonjes_Id,
        Total: row.Total,
      };
      return item;
    });
  };
  try {
    showLoading();

    //  POST request i 1 per FATURE SHITJE
    const response = await mainAxios.post(`/veprimekontabel`, {
      Kodi: Number(state.Kodi),
      Dokument_i_Fiksuar: Number(state.Dokument_i_Fiksuar),
      Data: formatDate(state.Data, true),
      Klasifikim4_ID: Number(state.Klasifikim4_ID),
      Shenim_1: state.Shenim_1,
      Operator: String(username),
      Veprimi: state.Veprimi,
    });
    if (response.status === 200) {
      setState(defaultState);
      setDisabled(true);
      setButtonClicked(false);
      setSearchString("");

      const newEntryID = response.data.Id;

      showToast("Fatura u rregjistrua.", { severity: "success" });

      // POST request i 2 per DTL
      const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
      const responseDtl = await mainAxios.post(
        `/veprimekontabel/dtl/bulk`,
        dtlDataArray
      );

      if (responseDtl.status === 200) {
        fetchFShitje(1);
        setCurrentAction(null);
      }
    } else {
      showToast("Gabim gjatë regjistrimit të artikujve", {
        severity: "error",
      });
    }
  } catch (error) {
    console.error("Error in POST request:", error);

    showToast("Gabim gjatë regjistrimit të faturës", {
      severity: "error",
    });
  } finally {
    hideLoading();
  }
};

// Kur Klikohet butoni i "MODIFIKO"
const handleModify = async (
  showLoading,
  state,
  username,
  setState,
  defaultState,
  setDisabled,
  setButtonClicked,
  setSearchString,
  showToast,
  rows,
  fetchFShitje,
  setCurrentAction,
  hideLoading,
  number,
  currentId
) => {
  const getItemsForSecondRequest = (rows, newEntryID) => {
    return rows.map((row) => {
      let item = {
        Master_Id: newEntryID,
        Kodi: row.Kodi,
        Id: row.Id,
        Tipi: row.Tipi,
        Shenim: state.Shenim,
        Pershkrim: row.Pershkrim,
        Mon: row.Mon,
        Kursi: row.Kursi,
        NrLL: row.NrLL,
        Kredi: row.Kredi,
        Debi: row.Debi,
        Punonjes_Id: row.Punonjes_Id,
        Total: row.Total,
      };
      return item;
    });
  };
  try {
    showLoading();
    // Requesti i 1 PUT per Faturen e shitjes
    const response = await mainAxios.put(`/veprimekontabel/${currentId}`, {
      Kodi: Number(state.Kodi),
      Dokument_i_Fiksuar: Number(state.Dokument_i_Fiksuar),
      Data: formatDate(state.Data, true),
      Klasifikim4_ID: Number(state.Klasifikim4_ID),
      Shenim_1: state.Shenim_1,
      Operator: String(username),
      Veprimi: state.Veprimi,
    });
    if (response.status === 200) {
      setState(defaultState);
      setDisabled(true);
      setButtonClicked(false);
      setSearchString("");

      const newEntryID = response.data.Id;

      showToast("Veprimi u modifikua.", {
        severity: "success",
      });

      //  PUT request i 2 per modifikimin e DTL
      const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
      const responseDtl = await mainAxios.put(
        `/veprimekontabel/dtl/bulk`,
        dtlDataArray
      );

      if (responseDtl.status === 200) {
        fetchFShitje(number);
        setCurrentAction(null);
      }
    }
  } catch (error) {
    console.error("Error in POST request:", error);
    showToast("Gabim gjatë modifikimit të veprimit.", {
      severity: "error",
    });
  } finally {
    hideLoading();
  }
};

export { handleAdd, handleModify };
