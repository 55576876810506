import React, { useState, useEffect, useContext } from "react";
import TePergjithshme from "./TePergjithshmeBelow";
import NavBar from "../../../components/Navbar/NavBar";
import SideBar from "../../../components/Navbar/SideBar";
import "../../paneliIartikujve/Artikuj.scss";
import mainAxios from "../../../services/axios";
import { useSelector } from "react-redux";
import { getLlogari } from "../../../store/globalSlice";
import { useDispatch } from "react-redux";
import PopUpConfirm from "../../fature-blerje/tabet/PopUpConfirm";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";
import PaneliTabeve from "../../paneliIartikujve/PaneliTabeve";

// function
import incrementKodi from "./Function/IncrementKodi";
import CheckboxHandler from "../../paneliIartikujve/functions/CheckboxHandler";
import { useToast } from "../../../components/context/ToastContext";
import BasicDemo from "./TreeTable";

const Llogari = ({
  currentAction,
  setCurrentAction,
  hidePupUp,
  setHidePupUp,
  isMobile,
  disabled,
  setDisabled,
  drawerOpen,
  setDrawerOpen,
}) => {
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const showToast = useToast();
  const [data, setdata] = useState([]);
  const [currentId, setCurrentId] = useState("");
  const dispatch = useDispatch();
  const [isEditingAllowed, setIsEditingAllowed] = useState(false);
  const [checkModify, setCheckModify] = useState(false);
  const [disabledBtnDelete, setDisabledBtnDelete] = useState(false);

  useEffect(() => {
    if (currentId) {
      setDisabledBtnDelete(true);
    } else {
      setDisabledBtnDelete(false);
    }
  }, [currentId]);

  const onAddClick = () => {
    setCurrentId("");
    setCurrentAction("add");
  };

  // redux llogari (get requesti i artikujve)
  const llogariRedux = useSelector((state) => state.globalSlice.llogari);
  const llogari = llogariRedux;

  useEffect(() => {
    setdata(llogari);
  }, [llogari]);

  const defaultState = {
    Kodi: null,
    Pershkrim: null,
    AP: null,
    Tarifa_Kodi: null,
    Pershkrim_Kredi: null,
    Pershkrim_Debi: null,
    Origjina: null,
    Veprime: null,
    ImageIndex: null,
    Aktiv: null,
    Selektuar: null,
    Rendit: null,
    Shitje_V: null,
    Blerje_V: null,
    Arka_MA_V: null,
    Arka_MP_V: null,
    Banka_MA_V: null,
    Banka_MP_V: null,
    Klient_V: null,
    Furnitor_V: null,
    KaVeprime: null,
    Shqip: null,
    Anglisht: null,
    Gjermanisht: null,
    Maqedonisht: null,
    Italisht: null,
    Turqisht: null,
    Kroatisht: null,
    Spanjisht: null,
    Arabisht: null,
    Frengjisht: null,
    Rusisht: null,
    Bullgarisht: null,
    Shenim1: null,
    Shenim2: null,
    TipVlereBlerje_ID: null,
    TipVlereShitje_ID: null,
    TipVlereImport_ID: null,
    Llogari_Kls01_ID: null,
    Llogari_Kls02_ID: null,
    Llogari_Kls03_ID: null,
    Llogari_Kls04_ID: null,
    Llogari_Kls05_ID: null,
    NukNdryshonVlerat: null,
    NotForSync: null,
  };

  const [state, setState] = useState(defaultState);
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleRowClick = (selectedRowData) => {
    setState(selectedRowData);
    setCurrentId(selectedRowData.Id);
  };

  useEffect(() => {
    // Function to select the first row

    const selectFirstRow = () => {
      if (data && data.length > 0 && !state.Kodi) {
        handleRowClick(data[0]);
      }
    };

    selectFirstRow();
  }, [data, state]);

  const updateRowData = async (rowData) => {
    if (state.Kodi === "" || state.Kodi === null) {
      showToast("Shtoni Kodin ose anulloje.", {
        severity: "info",
      });

      return;
    }
    if (state.Pershkrim === "" || state.Pershkrim === null) {
      showToast("Shtoni pershkrimin ose anulloje.", {
        severity: "info",
      });

      return;
    }

    try {
      const response = await mainAxios.put(`/art/${currentId}`, rowData);
      if (response.status === 200) {
        // Directly update the DataTable without calling getArtikujPag
        setdata((prevData) =>
          prevData.map((item) =>
            item.Id === currentId ? { ...item, ...rowData } : item
          )
        );
      }
    } catch (error) {
      console.error("Error updating data:", error);

      showToast("Error updating data.", {
        severity: "error",
      });
    }
  };

  const handleChangeTarifa = (key, value, rowData) => {
    if (isEditingAllowed) {
      const updatedRowData = {
        ...rowData,
        [key]: value,
      };
      updateRowData(updatedRowData);
    }
    setState((state) => {
      return {
        ...state,
        Tarifa_Kodi: value,
      };
    });
  };

  const handleConfirm = () => {
    try {
      setHidePupUp(false);
      setCurrentAction(null);
      setButtonClicked(false);
      setDisabled(true);
      setState("");
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(getLlogari(`/llogari/search`));
    }
  };

  const handleCancel = () => {
    setHidePupUp(false);
  };

  const anulloFature = () => {
    let excludeData = ["Data"];
    let hasValue = Object.keys(state)?.find(
      (key) => !excludeData.includes(key) && state[key] !== ""
    );
    if (hasValue) setHidePupUp(true);
  };

  return (
    <div className={`mainDiv_ftsh ${!drawerOpen ? "drawer-closed" : ""}`}>
      {!isMobile && <SideBar drawerOpen={drawerOpen} />}
      <div className="right-column">
        <NavBar
          currentAction={currentAction}
          setCurrentAction={setCurrentAction}
          setDisabled={setDisabled}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
        />
        <div className="template">
          {hidePupUp && (
            <PopUpConfirm onConfirm={handleConfirm} onCancel={handleCancel} />
          )}

          <div className="p-2 main-container">
            <div
              className="flex justify-between items-center"
              style={{ height: "5%" }}
            >
              {/* Paneli i tabeve do te vendoset ketu */}
              <PaneliTabeve
                incrementKodi={incrementKodi}
                fromLlogari={true}
                defaultState={defaultState}
                onAddClick={onAddClick}
                setDisabled={setDisabled}
                setCheckModify={setCheckModify}
                setState={setState}
                setButtonClicked={setButtonClicked}
                buttonClicked={buttonClicked}
                isMobile={isMobile}
                disabledBtnDelete={disabledBtnDelete}
                anulloFature={anulloFature}
                disabled={disabled}
                checkModify={checkModify}
                setHidePupUp={setHidePupUp}
                setCurrentAction={setCurrentAction}
                state={state}
                currentId={currentId}
                currentAction={currentAction}
                handleRowClick={handleRowClick}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
              {/* <CheckboxHandler
                setIsModifying={setIsModifying}
                isMobile={isMobile}
                setIsEditingAllowed={setIsEditingAllowed}
                setCheckModify={setCheckModify}
                setButtonClicked={setButtonClicked}
                setDisabled={setDisabled}
                setCurrentAction={setCurrentAction}
                buttonClickedModifiko={buttonClickedModifiko}
              /> */}
            </div>
            <BasicDemo llogari={llogari} handleRowClick={handleRowClick} />

            <div className="card_below_artikuj" style={{ height: "25%" }}>
              <TePergjithshme
                handleChangeTarifa={handleChangeTarifa}
                isMobile={isMobile}
                disabled={disabled}
                state={state}
                setState={setState}
                isEditingAllowed={isEditingAllowed}
                updateRowData={updateRowData}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {!isMobile && <Footer className="footer-position" />} */}
    </div>
  );
};

export default Llogari;
