import React from "react";
import SearchBar from "../Search/SearchBar";
import SherbimeDialog from "../../../pages/fature-blerje/minimodal/SherbimeDialog";
import MultiSelect from "../MultiSelect/MultiSelect";

const Header = ({
  placeholder,
  searchTerm,
  setSearchTerm,
  columns,
  onColumnToggle,
  selectedColumns,
  additionalProps,
  showDialog = false,
  dialogData = null,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <span
        style={{ width: "20rem", display: "flex" }}
        className="p-input-icon-right"
      >
        <SearchBar
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {showDialog && dialogData && (
          <SherbimeDialog
            numSherbime={`${dialogData?.length || 0} Rekorde`}
            sherbime={dialogData}
          />
        )}
      </span>
      <MultiSelect
        className={additionalProps?.className || "selectDefault"}
        value={selectedColumns}
        options={columns}
        optionLabel="title"
        onChange={(e) => onColumnToggle(e, additionalProps?.type || "default")}
        style={{ width: "3em", height: "2em" }}
      />
    </div>
  );
};

export default Header;
