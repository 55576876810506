import React, { useState, useEffect } from "react";
import { Button, ButtonGroup } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import mainAxios from "../../services/axios";
import "./Artikuj.scss";
import { getArtikujPag, getLlogari } from "../../store/globalSlice";
import { useDispatch } from "react-redux";
import { useUser } from "../../zustand/common";
import ArticleHandler from "./functions/ArtikujHandler";
import DeleteConfirmationArtikuj from "../fature-blerje/minimodal/DeleteArtikuj";
import { useToast } from "../../components/context/ToastContext";
import LlogariHandler from "../kontabilizime/Llogari/Function/LlogariHandler";

const PaneliTabeve = ({
  incrementKodi,
  defaultState,
  onAddClick,
  setDisabled,
  setCheckModify,
  state,
  setState,
  setButtonClickedModifiko,
  setButtonClicked,
  buttonClicked,
  isModifying,
  isMobile,
  disabledBtnDelete,
  anulloFature,
  disabled,
  checkModify,
  fromLlogari,
  setHidePupUp,
  setCurrentAction,
  currentPage,
  currentId,
  numPerPage,
  currentAction,
  handleRowClick,
  showLoading,
  hideLoading,
}) => {
  const showToast = useToast();
  const { user } = useUser();
  const nipt = user.nipt;
  const dispatch = useDispatch();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const cancelDelete = () => {
    setShowConfirmationPopup(false);
  };

  const deleteFature = async (Id) => {
    try {
      const response = await mainAxios.delete(`/art/${state.Id}`);
      if (response.status === 200) {
        try {
          setShowConfirmationPopup(false);

          dispatch(
            getArtikujPag(
              `/artikull/pag?page=${currentPage}&&per_page=${numPerPage}`
            )
          );

          showToast("Rreshti u fshi me sukses.", {
            severity: "info",
          });
        } catch (error) {
          console.error(error);

          showToast("Rreshti nuk mund te fshihet.", {
            severity: "error",
          });
        }
      }
    } catch (error) {
      console.error(error);
      showToast("Nuk mund te fshihet.", {
        severity: "error",
      });
    }
  };

  const deleteLlogari = async (Id) => {
    try {
      const response = await mainAxios.delete(`/llogari/${Id}`);
      if (response.status === 200) {
        try {
          setShowConfirmationPopup(false);

          dispatch(getLlogari(`/search/llogari`));

          showToast("Rreshti u fshi me sukses.", {
            severity: "info",
          });
        } catch (error) {
          console.error(error);

          showToast("Rreshti nuk mund te fshihet.", {
            severity: "error",
          });
        }
      }
    } catch (error) {
      console.error(error);
      showToast("Nuk mund te fshihet.", {
        severity: "error",
      });
    }
  };

  const confirmDelete = async () => {
    if (fromLlogari) {
      await deleteLlogari(currentId);
    } else {
      await deleteFature(currentId);
    }
  };

  const handleDeleteClick = async () => {
    try {
      const response = await mainAxios.get(
        `/fshirje/check?db_name=${nipt}&table_name=ART&kodi=${state.Kodi}`
      );

      if (response.data.Status !== 0) {
        showToast("Ka te dhena nuk mund te fshihet", {
          severity: "warn",
        });
      } else {
        setShowConfirmationPopup(true);
      }
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  const handleDeleteClickLlogari = async () => {
    try {
      setShowConfirmationPopup(true);
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  const handleRegister = () => {
    if (state.Kodi === "" || state.Kodi === null) {
      showToast("Shtoni Kodin ose anulloje.", {
        severity: "info",
      });

      return;
    }
    if (state.Pershkrim === "" || state.Pershkrim === null) {
      showToast("Shtoni pershkrimin ose anulloje.", {
        severity: "info",
      });

      return;
    }

    if (currentAction === "add" && !fromLlogari) {
      handleAdd();
    } else {
      handleAddLlogari();
    }
  };

  //thirrja e userit aktual
  const [username, setUsername] = useState("");

  useEffect(() => {
    const storedData = localStorage.getItem("loginData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUsername(parsedData.username);
    }
  }, []);

  const { handleAdd } = ArticleHandler({
    state,
    handleRowClick,
    setDisabled,
    setButtonClicked,
    setButtonClickedModifiko,
    currentPage,
    numPerPage,
    username,
    showLoading,
    hideLoading,
  });

  const { handleAddLlogari } = LlogariHandler({
    state,
    handleRowClick,
    setDisabled,
    setButtonClicked,
    currentPage,
    numPerPage,
    username,
    showLoading,
    hideLoading,
  });

  return (
    <>
      {showConfirmationPopup && (
        <DeleteConfirmationArtikuj
          contexDelete={`${state.Pershkrim}`}
          currentId={currentId}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          setDisabled={setDisabled}
          defaultState={defaultState}
          setState={setState}
          setButtonClicked={setButtonClicked}
        />
      )}

      <ButtonGroup className="mb-2 mt-1">
        <Button
          onClick={async (e) => {
            e.preventDefault();

            let updatedData;

            if (fromLlogari) {
              if (state.Veprime) {
                showToast("Eshte analize.", {
                  severity: "info",
                });

                return;
              }
              updatedData = await incrementKodi({ state, showToast });

              const updatedState = {
                ...defaultState,
                Kodi: updatedData.Kodi,
              };
              setState(updatedState);
            } else {
              updatedData = await incrementKodi({ state, showToast });

              const updatedState = {
                ...defaultState,
                Kodi: updatedData.Kodi,
                Art_Kls01_Kodi: updatedData.Art_Kls01_Kodi,
                Tarifa_Kodi: updatedData.Tarifa_Kodi,
                Skema_Kodi: updatedData.Skema_Kodi,
                Njesi_Kodi: updatedData.Njesi_Kodi,
                Tvsh: updatedData.Tvsh,
              };
              setState(updatedState);
            }

            setDisabled(false);
            setCheckModify(false);
            setButtonClickedModifiko(true);
            // addRow(); // Uncomment if you want to call addRow() here
            onAddClick();
            setButtonClicked(true);
          }}
          disabled={buttonClicked || isModifying}
        >
          <PostAddIcon />
          {isMobile ? null : "Shtim"}
        </Button>

        <Button
          className="p-1"
          disabled={disabledBtnDelete}
          onClick={(e) => {
            e.preventDefault();
            {
              fromLlogari ? handleDeleteClickLlogari() : handleDeleteClick();
            }
            // setButtonClicked(false);
          }}
        >
          <DeleteIcon />
          {isMobile ? null : "Fshije"}
        </Button>

        <Button
          onClick={(e) => {
            e.preventDefault();
            anulloFature();
          }}
          disabled={disabled || checkModify}
        >
          <ClearIcon />
          {isMobile ? null : "Anullim"}
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            setHidePupUp(false);
            setCurrentAction(null);
            setButtonClicked(false);
            setDisabled(true);
            // setState("");
            handleRegister();
          }}
          disabled={disabled || checkModify}
          type="submit"
        >
          <AppRegistrationIcon />
          {isMobile ? null : "Regjistro"}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default PaneliTabeve;
