import React, { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useUser } from "../../zustand/common";

// Create a context
const VpkContext = createContext();

// Create a provider for the context
export const VpkProvider = ({ children }) => {
  const { user } = useUser();
  const username = user.username;

  let defaultState = {
    Kodi: null,
    Data: null,
    Shenim_1: "",
    Operator: username,
    Selektuar: null,
    Mbyllur: null,
    Punonjes_Id: null,
    Dokument_i_Fiksuar: null,
    Veprimi: null,
    Klasifikim1_ID: null,
    Klasifikim2_ID: null,
    Klasifikim3_ID: null,
    Klasifikim4_ID: null,
    OperatorInsert: null,
    Magazina_Kodi: null,
    Fatura: null,
    Fugon: null,
    NotForSync: null,
  };

  const [state, setState] = useState(defaultState);

  return (
    <VpkContext.Provider
      value={{
        state,
        setState,
        defaultState,
      }}
    >
      {children}
    </VpkContext.Provider>
  );
};

// Custom hook to use the context
export const useVpkContext = () => useContext(VpkContext);
