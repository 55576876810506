import mainAxios from "../../../../services/axios";
import { formatDate } from "../../Function/formatDate";

// Kur Klikohet butoni i "SHTIM"
const handleAdd = async (
  urlKey,
  showLoading,
  state,
  username,
  setState,
  defaultState,
  setDisabled,
  setButtonClicked,
  setSearchString,
  showToast,
  rows,
  tipiDTL,
  tipiArkes,
  fetchFShitje,
  setCurrentAction,
  hideLoading
) => {
  const getItemsForSecondRequest = (rows, newEntryID) => {
    return rows.map((row) => {
      let item = {
        Master_Id: newEntryID,
        Pershkrim: row.Pershkrim,
        Vlera: row.Vlera,
        Sasia: row.Sasia,
        Tipi: row.Tipi,
        SkontoAgjent: row.SkontoAgjent,
        Cmimi: row.Cmimi,
        Cmimi_Pa_Tvsh: row.Cmimi_Pa_Tvsh,
        Operator: row.Operator,
        Vlera_Pa_Tvsh: row.Vlera_Pa_Tvsh,
        Kodi: row.Kodi,
        KLFU_Kodi: row.KLFU_Kodi,
        Tvsh: row.Tvsh,
        DateGarancie: row.DateGarancie,
        Rimbursim_Cmimi: row.Rimbursim_Cmimi,
        NrLL: row.NrLL,
        Shenim2: row.Shenim2,
        IdOld: row.IdOld,
        Skonto_VleraAgjent: row.Skonto_VleraAgjent,
        Art_ID: row.Art_ID,
        Punonjes_Id: row.Punonjes_Id,
        BarKod: row.BarKod,
        Njesi_Kodi: row.Njesi_Kodi,
        Skonto_Vlera: row.Skonto_Vlera,
        Total: row.Total,
        Magazina_Kodi: row.Magazina_Kodi,
        TipVlere_ID: row.TipVlere_ID,
        Tvsh_Vlera: row.Tvsh_Vlera,
        NrSerik: row.NrSerik,
        SasiaPak: row.SasiaPak,
        Cmimi_Kosto: row.Cmimi_Kosto,
        Skonto: row.Skonto,
      };
      return item;
    });
  };
  try {
    showLoading();

    //  POST request i 1 per Oferte
    const response = await mainAxios.post(`${urlKey}`, {
      Kodi_Org: state.Kodi_Org,
      Tip_Cmimi: state.Tip_Cmimi,
      Menyra_Pageses_ID: Number(state.Menyra_Pageses_ID),
      NrSerik: state.NrSerik,
      Veprimi: state.Veprimi,
      // Klasifikim4_ID: Number(state.Klasifikim4_ID),
      Shenim: state.Shenim,
      Kodi: Number(state.Kodi),
      Eksport: Number(state.Eksport),
      Magazina_Kodi: state.Magazina_Kodi,
      Afati_PagesesDite: Number(state.Afati_PagesesDite),
      // Klasifikim1_ID: Number(state.Klasifikim1_ID),
      Data_Deklarimit: formatDate(state.Data_Deklarimit, true),
      Data: formatDate(state.Data, true),
      Mon: state.Mon,
      Kursi: Number(state.Kursi),
      Dep_Kodi: state.Dep_Kodi,
      KLFU_Kodi: state.KLFU_Kodi,
      Paguar: Number(state.Paguar),
      Arka_Banka_Kodi: String(state.Arka_Banka_Kodi),
      Paguar_ne: String(state.Menyra_Pageses_ID),
      Operator: String(username),
      // Klasifikim2_ID: Number(state.Klasifikim2_ID),
      Qyteti_Kodi: state.Qyteti_Kodi,
      // Klasifikim3_ID: Number(state.Klasifikim3_ID),
      Afati_PagesesData: formatDate(state.Afati_PagesesData, true),
      KLFU_Pershkrim: state.KLFU_Pershkrim,
    });
    if (response.status === 200) {
      setState(defaultState);
      setDisabled(true);
      setButtonClicked(false);
      setSearchString("");

      const newEntryID = response.data.Id;

      showToast("Oferta u rregjistrua.", { severity: "success" });

      // POST request i 2 per DTL
      const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
      const responseDtl = await mainAxios.post(
        `/oferte/dtl/bulk`,
        dtlDataArray
      );

      if (responseDtl.status === 200 && tipiDTL === "AR") {
        fetchFShitje(1);
        setCurrentAction(null);
      }
    } else {
      showToast("Gabim gjatë regjistrimit të artikujve", {
        severity: "error",
      });
    }
  } catch (error) {
    console.error("Error in POST request:", error);

    showToast("Gabim gjatë regjistrimit të ofertes", {
      severity: "error",
    });
  } finally {
    hideLoading();
  }
};
let hasArObject;

// Kur Klikohet butoni i "MODIFIKO"
const handleModify = async (
  urlKey,
  showLoading,
  state,
  username,
  setState,
  defaultState,
  setDisabled,
  setButtonClicked,
  setSearchString,
  showToast,
  rows,
  tipiDTL,
  tipiArkes,
  fetchFShitje,
  setCurrentAction,
  hideLoading,
  number,
  currentId
) => {
  const getItemsForSecondRequest = (rows, newEntryID) => {
    hasArObject = rows.some((row) => row.Tipi === "AR");
    return rows.map((row) => {
      let item = {
        Master_Id: newEntryID,
        Id: row.Id,
        Pershkrim: row.Pershkrim,
        Vlera: row.Vlera,
        Sasia: row.Sasia,
        Tipi: row.Tipi,
        SkontoAgjent: row.SkontoAgjent,
        Cmimi: row.Cmimi,
        Operator: row.Operator,
        Vlera_Pa_Tvsh: row.Vlera_Pa_Tvsh,
        Kodi: row.Kodi,
        KLFU_Kodi: row.KLFU_Kodi,
        Tvsh: row.Tvsh,
        DateGarancie: row.DateGarancie,
        Rimbursim_Cmimi: row.Rimbursim_Cmimi,
        NrLL: row.NrLL,
        Shenim2: row.Shenim2,
        IdOld: row.IdOld,
        Skonto_VleraAgjent: row.Skonto_VleraAgjent,
        Art_ID: row.Art_ID,
        Punonjes_Id: row.Punonjes_Id,
        BarKod: row.BarKod,
        Njesi_Kodi: row.Njesi_Kodi,
        Skonto_Vlera: row.Skonto_Vlera,
        Cmimi_Pa_Tvsh: row.Cmimi_Pa_Tvsh,
        Total: row.Total,
        Magazina_Kodi: row.Magazina_Kodi,
        TipVlere_ID: row.TipVlere_ID,
        Tvsh_Vlera: row.Tvsh_Vlera,
        NrSerik: row.NrSerik,
        SasiaPak: row.SasiaPak,
        Cmimi_Kosto: row.Cmimi_Kosto,
        Skonto: row.Skonto,
      };
      return item;
    });
  };
  try {
    showLoading();
    // Requesti i 1 PUT per Faturen e shitjes
    const response = await mainAxios.put(`${urlKey}/${currentId}`, {
      Kodi_Org: state.Kodi_Org,
      Tip_Cmimi: state.Tip_Cmimi,
      Menyra_Pageses_ID: Number(state.Menyra_Pageses_ID),
      NrSerik: state.NrSerik,
      Veprimi: state.Veprimi,
      // Klasifikim4_ID: Number(state.Klasifikim4_ID),
      Shenim: state.Shenim,
      Kodi: Number(state.Kodi),
      Eksport: Number(state.Eksport),
      Magazina_Kodi: state.Magazina_Kodi,
      Afati_PagesesDite: Number(state.Afati_PagesesDite),
      // Klasifikim1_ID: Number(state.Klasifikim1_ID),
      Data_Deklarimit: formatDate(state.Data_Deklarimit, true),
      Data: formatDate(state.Data, true),
      Mon: state.Mon,
      Kursi: Number(state.Kursi),
      Dep_Kodi: state.Dep_Kodi,
      KLFU_Kodi: state.KLFU_Kodi,
      Paguar: Number(state.Paguar),
      Arka_Banka_Kodi: String(state.Arka_Banka_Kodi),
      Paguar_ne: String(state.Menyra_Pageses_ID),
      Operator: String(username),
      // Klasifikim2_ID: Number(state.Klasifikim2_ID),
      Qyteti_Kodi: state.Qyteti_Kodi,
      // Klasifikim3_ID: Number(state.Klasifikim3_ID),
      Afati_PagesesData: formatDate(state.Afati_PagesesData, true),
      KLFU_Pershkrim: state.KLFU_Pershkrim,
    });
    if (response.status === 200) {
      setState(defaultState);
      setDisabled(true);
      setButtonClicked(false);
      setSearchString("");

      const newEntryID = response.data.Id;

      showToast("Oferta u modifikua.", {
        severity: "success",
      });

      //  PUT request i 2 per modifikimin e DTL
      const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
      const responseDtl = await mainAxios.put(`/oferte/dtl/bulk`, dtlDataArray);

      if (responseDtl.status === 200 && hasArObject) {
        fetchFShitje(number);
        setCurrentAction(null);
      }
    }
  } catch (error) {
    console.error("Error in POST request:", error);
    showToast("Gabim gjatë modifikimit të ofertes.", {
      severity: "error",
    });
  } finally {
    hideLoading();
  }
};

export { handleAdd, handleModify };
