import React from "react";
import { InputText } from "primereact/inputtext";

const SearchBar = ({ value, onChange, placeholder }) => {
  return (
    <>
      <i className="pi pi-search" />
      <InputText
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="p-inputtext"
      />
    </>
  );
};

export default SearchBar;
