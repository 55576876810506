// registerHandler.js
import mainAxios from "../../../services/axios";

const handleRegister = async (
  state,
  showToast,
  tipiDTL,
  selectedArkaMon,
  rows,
  deletedRowIds,
  currentAction,
  handleFunctionAdd,
  handleFunctionModify,
  setDeletedRowIds,
  fromVPK
) => {
  if (!state.KLFU_Kodi && !fromVPK) {
    showToast("Cakto Klientin!");
    return;
  }

  if (tipiDTL === "AR" && !fromVPK) {
    if (!state.Magazina_Kodi) {
      showToast("Cakto Magazinen!");
      return;
    }
  }

  if (
    state.Mon &&
    selectedArkaMon &&
    state.Mon !== selectedArkaMon &&
    !fromVPK
  ) {
    showToast("Monedha e Arkes/Bankes e ndryshme nga monedha e fatures!");
    return;
  }

  if (rows.length === 0) {
    !fromVPK
      ? showToast("Shto Artikuj ose anullo faturen!")
      : showToast("Shto Llogari ose anullo veprimin!");
    return;
  } else {
    try {
      if (!fromVPK) {
        for (const id of deletedRowIds) {
          await mainAxios.delete(`/fature/shitje/dtl/${id}`);
        }
      } else if (rows.length !== 0 && fromVPK) {
        for (const id of deletedRowIds) {
          await mainAxios.delete(`/veprimekontabel/dtl/${id}`);
        }
      }
    } catch (error) {
      console.error("Error deleting row:", error);
    } finally {
      setDeletedRowIds([]);
    }
  }

  if (currentAction === "add") {
    handleFunctionAdd();
  } else if (currentAction === "modify") {
    handleFunctionModify();
  }
};

export default handleRegister;
