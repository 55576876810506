const columns = [
  { field: "ID", title: "ID", maxWidth: "100px" },
  { field: "BleresiNipt", title: "Nipt", maxWidth: "150px" },
  { field: "Status", title: "Status", maxWidth: "150px" },
  { field: "NumerDokumenti", title: "Numer Dokumenti", maxWidth: "150px" },
  { field: "EIC", title: "EIC", maxWidth: "150px" },
  { field: "Vlera", title: "Vlera", maxWidth: "150px" },
  { field: "Tipi", title: "Tipi", maxWidth: "150px" },
  { field: "DataPagesesDeri", title: "Data Pageses Deri", maxWidth: "150px" },
  { field: "PartyType", title: "Party Type", maxWidth: "150px" },
  { field: "DateRegjistrimi", title: "Date Regjistrimi", maxWidth: "150px" },
];

export { columns };
