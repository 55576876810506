import mainAxios from "../../../../services/axios";

const incrementKodi = async ({ state, showToast }) => {
  try {
    // Fetch the latest data from the endpoint
    const response = await mainAxios.get(
      `/llogari/newKodi?Origjina=${state.Kodi}`
    );
    // Return all the updated fields along with the incremented Kodi
    return {
      Kodi: response.data.newKodi,
    };
  } catch (error) {
    console.error("Error fetching and updating Kodi:", error);

    const newKodi = state.Kodi + 1;
    // Return all the updated fields along with the incremented Kodi
    return {
      Kodi: newKodi,
    };
  }
};

export default incrementKodi;
