import React from "react";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import Klienti from "../../minimodal/Klienti";
// import Qytet from "../../minimodal/Qytet";
import Paper from "@mui/material/Paper";

const TabKlienti = ({
  toggleState,
  disabled,
  state,
  handleChange,
  fetchPost,
  handleKlientiType,
  qytetet,
  klienti,
  searchString,
  setSearchString,
  qytetKlienti,
  shenim,
  nipt,
  fleteDalje,
  fromArka,
}) => {
  // const qytetetKodi = qytetet.map((item) => item.Kodi);
  // const numQyt = qytetet.length;
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });

  return (
    <div className={toggleState ? "content  active-content" : "content"}>
      <div className="grid gap-2 grid-cols-2" style={{ height: "125px" }}>
        <div className=" relative flex w-full items-center justify-center rounded-tr-lg rounded-br-lg">
          {/* //////// */}
          <Autocomplete
            disablePortal
            disabled={disabled}
            id="combo-box-demo7"
            options={klienti}
            getOptionLabel={(option) => (option.Kodi ? `${option.Kodi}` : "")}
            value={
              searchString
                ? (klienti || []).find((option) => option.Kodi === searchString)
                : (state &&
                    (klienti || []).find(
                      (option) => option.Kodi === state.KLFU_Kodi
                    )) ||
                  null
            }
            onChange={(event, newValue) => {
              setSearchString(newValue ? newValue.Kodi : "");
              handleKlientiType(event, newValue);
            }}
            sx={{ width: "100%" }}
            size="small"
            filterOptions={filterOptions}
            renderInput={(params) => (
              <TextField disabled={disabled} {...params} label="Klienti" />
            )}
            renderOption={(props, option) => (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                {...props}
              >
                <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
              </div>
            )}
            PaperComponent={(props) => <Paper {...props} />}
          />
          {/* //////MiniModal */}
          <div className="transition-all hover:scale-110">
            <Klienti
              fetchMonedhat={fetchPost}
              klienti={klienti}
              qytetet={qytetet}
              // shtetet={shtetet}
            />
          </div>
        </div>
        {fleteDalje ? <div></div> : ""}
        {nipt ? (
          <TextField
            disabled={disabled}
            type="text"
            onChange={(e) => handleChange("NIPT", e.target.value)}
            label="Nipt"
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            value={state?.NIPT || ""}
          />
        ) : null}

        {fromArka && (
          <TextField
            disabled={disabled}
            type="text"
            onChange={(e) => handleChange("Nipt", e.target.value)}
            label="Nipt"
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            value={state?.Nipt || ""}
          />
        )}

        <TextField
          disabled={disabled}
          onChange={(e) => handleChange("KLFU_Pershkrim", e.target.value)}
          label="Pershkrim"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.KLFU_Pershkrim || ""}
        />
        {fleteDalje ? <div></div> : ""}
        {/* ////////////////// */}
        {qytetKlienti ? (
          <div className="relative flex w-full items-center justify-start rounded-tr-lg rounded-br-lg">
            <Autocomplete
              disablePortal
              disabled={disabled}
              id="combo-box-qyteti8"
              options={qytetet}
              filterOptions={filterOptions}
              getOptionLabel={(option) => (option ? option.Kodi : "")}
              onChange={(e, newValue) =>
                handleChange("Qyteti_Kodi", newValue ? newValue.Kodi : "")
              }
              value={
                qytetet.find((qytet) => qytet.Kodi === state?.Qyteti_Kodi) ||
                null
              }
              size="small"
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Qytetet" />
              )}
              renderOption={(props, option) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  {...props}
                >
                  <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                  <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                </div>
              )}
            />

            <div className="transition-all hover:scale-110">
              {/* <Qytet
                fetchMonedhat={fetchPost}
                qytetet={qytetetKodi}
                numQyt={numQyt}
              /> */}
            </div>
          </div>
        ) : null}

        {fromArka && <div></div>}
        {fleteDalje ? <div style={{ height: "125px" }}></div> : ""}
        {shenim ? (
          <textarea
            disabled={disabled}
            cols="38"
            rows="3"
            value={state?.Shenim || ""}
            onChange={(e) => handleChange("Shenim", e.target.value)}
            placeholder="Shënim"
            className="shenim resize-none rounded-md bg-white"
          ></textarea>
        ) : null}
      </div>
    </div>
  );
};

export default TabKlienti;
