// TableComponent.js
import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CircularProgress } from "@mui/material";

export default function TableComponent({ data, loading }) {
  // const columns = [
  //   { field: "Tabela", title: "Dokumenti" },
  //   { field: "Id_Org", title: "ID" },
  //   { field: "Veprimi_Org", title: "Veprimi" },
  // ];

  const columns = [
    { field: "TABELA", title: "Dokumenti" },
    { field: "ID", title: "ID" },
    { field: "TIPI", title: "Veprimi" },
  ];

  return (
    <DataTable
      reorderableColumns
      value={loading ? [] : data}
      scrollable
      stripedRows
      dataKey="Id"
      autoLayout
      filterDisplay="menu"
      style={{ width: "60%" }}
      emptyMessage={loading ? <CircularProgress /> : "Nuk ka të dhëna"}
    >
      {loading
        ? [<Column key="loading" field="loading" header="Loading..." />]
        : columns.map((col, index) => (
            <Column key={index} field={col.field} header={col.title} />
          ))}
    </DataTable>
  );
}
