import React from "react";
import { MultiSelect } from "primereact/multiselect";

const SearchBar = ({ value, className, options, optionLabel, onChange }) => {
  return (
    <>
      <MultiSelect
        className={className}
        value={value}
        options={options}
        optionLabel={optionLabel}
        onChange={onChange}
        style={{ width: "3em", height: "2em" }}
      />
    </>
  );
};

export default SearchBar;
