import React, { useState, useEffect } from "react";
import "./NavStyles/SideBar.scss";
import { PanelMenu } from "primereact/panelmenu";
import { ScrollPanel } from "primereact/scrollpanel";
import { InputText } from "primereact/inputtext";
// import { Dialog } from "primereact/dialog";
import nodes from "./SideBarItems/SideBarArray";
import ReportDialog from "./ReportDialog";
import ArkaDialog from "./DeklarimiArkes";
import Logoja from "../../assets/Logoja.png";
import { Link, useNavigate } from "react-router-dom";
import Xhiro from "../../pages/MbylljeTurni/Xhiro";
import FatureThjeshtuar from "../../pages/fatureShitje/FatureThjeshtuar/FatureThjeshtuar";
import EInvoice from "../../pages/fatureShitje/EInvoice/EInvoice";
import { FtshProvider } from "../context/FtshState";
import { useUser } from "../../zustand/common";
import KostoKalkulim from "../../pages/paneliIartikujve/konstoKalkulim/KostoKalkulim";
import PikeKlientash from "../../pages/POS/PikeKlientash/PikeKlientash";
import PostimDitar from "../../pages/kontabilizime/postimNeDitar/PostimDitar";
import SkemaKontabilizimit from "../../pages/kontabilizime/skemaKontabilizimit/SkemaKontabilizimit";
import RivlersimiKursit from "../../pages/kontabilizime/rivlersimiKursit/RivlersimiKursit";
import MbylljeTeASH from "../../pages/kontabilizime/mbylljaTeASH/MbylljeTeASH";

const SideBar = ({ drawerOpen }) => {
  const { userAutomatikishtFromCntext, userGrupId } = useUser();
  const [search, setSearch] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogText, setText] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showXhiro, setShowXhiro] = useState(false);
  const [showFtshThjeshtuar, setShowFtshThjeshtuar] = useState(false);
  const [showEInvoice, setShowEInvoice] = useState(false);
  const [showRivlersimiKursit, setShowRivlersimiKursit] = useState(false);
  // const [dialogIndex, setDialogIndex] = useState("");
  const [selectedDialog, setSelectedDialog] = useState(null);
  const [dialogRaportButtonsKeys, setDialogRaportButtonsKeys] = useState([]);
  const [dialogArkaButtonsKeys, setDialogArkaButtonsKeys] = useState([]);
  const navigate = useNavigate();
  const [openDialogKosto, setOpenDialogKosto] = useState(false);
  const [openDialogPikeKlientash, setOpenDialogPikeKlientash] = useState(false);
  const [openDialogMbylljeTeASH, setOpenDialogMbylljeTeASH] = useState(false);
  const [openDialogDitar, setOpenDialogDitar] = useState(false);
  const [openDialogSkema, setOpenDialogSkema] = useState(false);

  // Open dialog handler
  const handleOpenDialogKosto = () => {
    setOpenDialogKosto(true);
  };

  // Close dialog handler
  const handleCloseDialogKosto = () => {
    setOpenDialogKosto(false);
  };

  // Open dialog handler
  const handleOpenDialogPikeKlientash = () => {
    setOpenDialogPikeKlientash(true);
  };

  const handleCloseDialogPikeKlientash = () => {
    setOpenDialogPikeKlientash(false);
  };

  // Open dialog handler
  const handleOpenDialogMbylljeTeASH = () => {
    setOpenDialogMbylljeTeASH(true);
  };

  const handleCloseDialogMbylljeTeASH = () => {
    setOpenDialogMbylljeTeASH(false);
  };

  // Open dialog handler
  const handleOpenDialogDitar = () => {
    setOpenDialogDitar(true);
  };

  const handleCloseDialogDitar = () => {
    setOpenDialogDitar(false);
  };

  // Open dialog handler
  const handleOpenDialogSkema = () => {
    setOpenDialogSkema(true);
  };

  const handleCloseDialogSkema = () => {
    setOpenDialogSkema(false);
  };

  const handleButtonClick = (element) => {
    if (element.RaportButtons) {
      // setDialogIndex(element.index);
      setDialogContent(element.buttonRaport);
      setText(element.text);
      setDialogRaportButtonsKeys(element.RaportButtons.map((rb) => rb.key));
      setSelectedDialog("ReportDialog");
      openDialog(element.buttonRaport, element.RaportButtons);
      setDialogVisible(true);
    }
    if (element.ArkaButtons) {
      // setDialogIndex(element.index);
      setDialogContent(element.buttonRaport);
      setDialogArkaButtonsKeys(element.ArkaButtons.map((ab) => ab.key));
      setSelectedDialog("ArkaDialog");
      openDialog(element.buttonRaport, element.ArkaButtons);
      setDialogVisible(true);
    }
  };

  const openDialog = (content, raportButtonsKeys) => {
    setDialogContent(content);
    setDialogRaportButtonsKeys(raportButtonsKeys.map((rb) => rb.key));
    setDialogVisible(true);
  };

  const searchTree = (element, matchingTitle) => {
    if (element.label.toLowerCase().includes(matchingTitle)) {
      return element;
    } else if (element?.items != null) {
      let matchedItems = [];
      element.items.forEach((item) => {
        const result = searchTree(item, matchingTitle);
        if (result) matchedItems.push(result);
      });
      if (matchedItems.length > 0) {
        return { ...element, items: matchedItems };
      }
    }
    return null;
  };

  const generateMenuModel = (items) => {
    return items
      .map((item) => {
        // Only include the item with key "2-3" if automatikisht === 1
        if (
          item.key === "2-3" &&
          (userAutomatikishtFromCntext !== 1 || userGrupId !== 1)
        ) {
          return null; // Don't return this item if automatikisht !== 1
        }

        if (
          item.key === "1-7-1-7" &&
          (userAutomatikishtFromCntext !== 1 || userGrupId !== 1)
        ) {
          return null; // Don't return this item if automatikisht !== 1
        }

        if (item.key === "1-1") {
          item.className = "bold-item"; // Add the CSS class
        }

        if (item.label === "Fature Thjeshtuar") {
          return {
            ...item,
            command: () => handleFtshThjeshtuatClick(),
          };
        }
        if (item.label === "E Invoice") {
          return {
            ...item,
            command: () => handleEInvoice(),
          };
        }
        if (item.label === "Rivleresimi i kursit") {
          return {
            ...item,
            command: () => handleRivlersimiKursit(),
          };
        }
        if (item.label === "Xhiro") {
          return {
            ...item,
            command: () => handleXhiroClick(),
          };
        }

        if (item.label === "Kosto Kalkulim") {
          return {
            ...item,
            command: () => handleOpenDialogKosto(),
          };
        }

        if (item.label === "Postim ne Ditar") {
          return {
            ...item,
            command: () => handleOpenDialogDitar(),
          };
        }
        if (item.label === "Skema e Kontabilizimit") {
          return {
            ...item,
            command: () => handleOpenDialogSkema(),
          };
        }
        if (item.label === "Pike Klientash") {
          return {
            ...item,
            command: () => handleOpenDialogPikeKlientash(),
          };
        }
        if (item.label === "Mbyllje e Te Ardhurave dhe Shpenzimeve") {
          return {
            ...item,
            command: () => handleOpenDialogMbylljeTeASH(),
          };
        }
        if (item.buttonRaport) {
          return {
            ...item,
            command: () => handleButtonClick(item),
          };
        } else if (item.path) {
          return {
            ...item,
            command: () => navigate(`${item.path}`),
          };
        } else if (item.items) {
          return {
            ...item,
            items: generateMenuModel(item.items), // Recursively generate menu items for submenus
          };
        }
        return item;
      })
      .filter(Boolean); // Filter out null values
  };

  // const filteredNavLinks = search
  //   ? nodes
  //       .map((item) => searchTree(item, search.toLowerCase()))
  //       .filter(Boolean)
  //   : nodes;

  const menuModel = generateMenuModel(nodes);

  const handleXhiroClick = () => {
    setShowXhiro(true);
  };

  const handleFtshThjeshtuatClick = () => {
    setShowFtshThjeshtuar(true);
  };
  const handleEInvoice = () => {
    setShowEInvoice(true);
  };

  const handleRivlersimiKursit = () => {
    setShowRivlersimiKursit(true);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1450);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1450);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  return (
    <div className={`sidebar  ${isMobile && drawerOpen ? "mobile-open" : ""}`}>
      {!isMobile && drawerOpen && (
        <div className="logo_container">
          {userAutomatikishtFromCntext === 1 || userGrupId === 1 ? (
            <Link
              to="/dashboard"
              className="flex"
              style={{ justifyContent: "flex-start", alignItems: "center" }} // Align to start
            >
              <img src={Logoja} className="pss-logo h-11 w-11" alt="" />
              <span
                className="ml-2 flex items-center"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                Dashboard
              </span>
            </Link>
          ) : (
            <Link
              to="/fature-shitje"
              className="flex"
              style={{ justifyContent: "flex-start", alignItems: "center" }} // Align to start
            >
              <img src={Logoja} className="pss-logo h-9 w-9" alt="" />
            </Link>
          )}
        </div>
      )}
      <ScrollPanel>
        <PanelMenu model={menuModel} />
      </ScrollPanel>

      {showFtshThjeshtuar && (
        <FtshProvider>
          <FatureThjeshtuar
            open={showFtshThjeshtuar}
            onClose={() => setShowFtshThjeshtuar(false)}
          />
        </FtshProvider>
      )}
      {showEInvoice && (
        <FtshProvider>
          <EInvoice
            isMobile={isMobile}
            open={showEInvoice}
            onClose={() => setShowEInvoice(false)}
          />
        </FtshProvider>
      )}
      {showRivlersimiKursit && (
        <FtshProvider>
          <RivlersimiKursit
            open={showRivlersimiKursit}
            isMobile={isMobile}
            onClose={() => setShowRivlersimiKursit(false)}
          />
        </FtshProvider>
      )}
      {showXhiro && (
        <Xhiro
          homeNavbar={true}
          isFullscreen={isFullscreen}
          setIsFullscreen={setIsFullscreen}
          open={showXhiro}
          onClose={() => setShowXhiro(false)}
        />
      )}
      {openDialogKosto && (
        <KostoKalkulim
          open={openDialogKosto}
          onClose={() => handleCloseDialogKosto(false)}
        />
      )}

      {openDialogPikeKlientash && (
        <PikeKlientash
          open={openDialogPikeKlientash}
          onClose={() => handleCloseDialogPikeKlientash(false)}
        />
      )}

      {openDialogMbylljeTeASH && (
        <MbylljeTeASH
          open={openDialogMbylljeTeASH}
          onClose={() => handleCloseDialogMbylljeTeASH(false)}
        />
      )}

      {openDialogDitar && (
        <PostimDitar
          open={openDialogDitar}
          onClose={() => handleCloseDialogDitar(false)}
        />
      )}

      {openDialogSkema && (
        <SkemaKontabilizimit
          open={openDialogSkema}
          onClose={() => handleCloseDialogSkema(false)}
        />
      )}

      {selectedDialog === "ReportDialog" ? (
        <ReportDialog
          buttonRaport={dialogContent}
          text={dialogText}
          visible={dialogVisible}
          // index={dialogIndex}
          onHide={() => setDialogVisible(false)}
          content={dialogContent}
          raportButtonsKeys={dialogRaportButtonsKeys}
        />
      ) : (
        <ArkaDialog
          visible={dialogVisible}
          // index={dialogIndex}
          onHide={() => setDialogVisible(false)}
          content={dialogContent}
          raportButtonsKeys={dialogArkaButtonsKeys}
        />
      )}
    </div>
  );
};

export default SideBar;
