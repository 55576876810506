import React from "react";
import SearchBar from "../Search/SearchBar";
import SherbimeDialog from "../../../pages/fature-blerje/minimodal/SherbimeDialog";
import MultiSelect from "../MultiSelect/MultiSelect";

const HeaderArka = ({
  searchTermSherbime,
  setSearchTermSherbime,
  sherbime,
  columnsSherbime,
  onColumnToggle,
  selectedColumnsSherbime,
}) => {
  const numSherbime = `${sherbime ? sherbime.length : 0} Rekorde`;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <span
        style={{ width: "20rem", display: "flex" }}
        className="p-input-icon-right"
      >
        <SearchBar
          placeholder="Search in Arka..."
          value={searchTermSherbime}
          onChange={(e) => setSearchTermSherbime(e.target.value)}
        />

        <SherbimeDialog numSherbime={numSherbime} sherbime={sherbime} />
      </span>
      <MultiSelect
        className="selectArka"
        value={selectedColumnsSherbime}
        options={columnsSherbime}
        optionLabel="title"
        onChange={(e) => onColumnToggle(e, "arka")} // Specify type "sherbime"
        style={{ width: "3em", height: "2em" }}
      />
    </div>
  );
};

export default HeaderArka;
