import React, { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useUser } from "../../zustand/common";

// Create a context
const OfertContext = createContext();

// Create a provider for the context
export const OfertProvider = ({ children }) => {
  const { user } = useUser();
  const username = user.username;

  // redux konifgurime ndermarrje
  const konfigurimeRedux = useSelector(
    (state) => state.globalSlice.konfigurime
  );
  const konfigurim = konfigurimeRedux;

  const selectedMon = konfigurim?.[0]?.Mon || "ALL";

  // redux klienti (get requesti i klientit)
  const klientiRedux = useSelector((state) => state.globalSlice.klienti);
  const klienti = klientiRedux;

  // qyteti redux
  const qytetetRedux = useSelector((state) => state.globalSlice.qytetet);
  const qytetet = qytetetRedux;

  // shteti redux
  const shtetetRedux = useSelector((state) => state.globalSlice.shtetet);
  const shtetet = shtetetRedux.map((item) => item.Kodi);

  // redux magazina (get requesti i Magazines)
  const magazina = useSelector((state) => state.globalSlice.magazina);
  const defaultMag = magazina.map((item) => item.Kodi);

  // Redux state for monedha
  const monedhat = useSelector((state) => state.globalSlice.monedha);

  // Default currency: "Leke"
  const defaultMonedha =
    monedhat.find((item) => item.Kodi === selectedMon) ||
    monedhat.find((item) => item.Kodi === "ALL") ||
    {};
  const defaultKursi = defaultMonedha.Kursi || 1;

  // redux menyra (get requesti i Menyres-Pageses)
  const menyrapageses = useSelector((state) => state.globalSlice.menyraPageses);
  const defaultPagesa = menyrapageses.map((item) => item.Id);

  // redux arka/banka (get requesti i Arka/Banka)
  const arka = useSelector((state) => state.globalSlice.arkabanka);

  let defaultState = {
    // KLFU_Pershkrim: (klienti[0] && klienti[0].Pershkrim) || "",
    // KLFU_Kodi: (klienti[0] && klienti[0].Kodi) || "",
    KLFU_Pershkrim: "", // Kledi deshiron qe te jet bosh kur te klikohet shtim
    KLFU_Kodi: "", // Kledi deshiron qe te jet bosh kur te klikohet shtim
    Data: null, // Not a valid datetime
    Mon: defaultMonedha.Kodi || "", // Default currency is "Leke"
    Kursi: defaultKursi,
    // NIPT: (klienti[0] && klienti[0].Nipt) || "",
    NIPT: "", // Kledi deshiron qe te jet bosh kur te klikohet shtim
    // Qyteti_Kodi: (klienti[0] && klienti[0].Qyteti_Kodi) || "",
    Qyteti_Kodi: "", // Kledi deshiron qe te jet bosh kur te klikohet shtim
    Shenim: "",
    Menyra_Pageses_ID: defaultPagesa[0] || "",
    Arka_Banka_Kodi: (arka[0] && arka[0].Kodi) || "",
    Paguar: "", // Not a valid number
    Afati_PagesesData: null, // Not a valid datetime
    Afati_PagesesDite: null,
    Kodi_Org: "",
    Kodi: null, // Not a valid integer
    NrSerik: "",
    fisDocumentTypeKodi: "",
    Tip_Cmimi: "",
    Magazina_Kodi: defaultMag[0] || "",
    Veprimi: "",
    Operator: username,
    Data_Deklarimit: null, // Not a valid datetime
    Dep_Kodi: "",
    Targa_e_Mjetit: "",
    Eksport: null, // Not a valid integer
    Transportuesi_Nipt: "",
    Transportuesi_Adresa: "",
    Transportuesi_Pershkrim: "",
    Transport_Shenim: "",
    Transport_Data: null, // Not a valid datetime
  };

  const [state, setState] = useState(defaultState);

  return (
    <OfertContext.Provider
      value={{
        state,
        setState,
        klientiRedux,
        qytetet,
        shtetet,
        defaultState,
        menyrapageses,
        klienti,
        monedhat,
        arka,
      }}
    >
      {children}
    </OfertContext.Provider>
  );
};

// Custom hook to use the context
export const useOfertContext = () => useContext(OfertContext);
