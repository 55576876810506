import React, { useState } from "react";
import { Button, ButtonGroup } from "@mui/material";
import mainAxios from "../../../services/axios";
import DeleteConfirmationPopup from "../minimodal/DeleteConfirmation";
import PopUpConfirm from "./PopUpConfirm";

const fetchMap = {
  FatureShitje: "fetchFShitje",
  VeprimeKontabel: "fetchFShitje",
  Arka: "fetchArka",
  Banka: "fetchBanka",
  FatureBlerje: "fetchFBlerje",
  FleteHyrje: "fetchFHyrje",
  FleteDalje: "fetchFDalje",
  Oferte: "fetchFShitje",
  Inventar: "fetchFHyrje",
};

const deleteMap = {
  FatureShitje: "/fature/shitje/",
  VeprimeKontabel: "/veprimekontabel/",
  FatureBlerje: "/fature/blerje/",
  Arka: "/arka/",
  Banka: "/banka/",
  FleteHyrje: "/flete_hyrje/",
  FleteDalje: "/flete_dalje/",
  Oferte: "/oferte/",
  Inventar: "/inventar/",
};

const PaneliTabeve = ({
  popupContextKey,
  disabled,
  setDisabled,
  state,
  fatureDisabled,
  defaultState,
  setState,
  index,
  setIndex,
  responseData,
  setResponseData,
  lastObject,
  setLastObject,
  setHidePupUp,
  hidePupUp,
  setRows,
  anulloFature,
  button1Icon,
  button1Text,
  button2Icon,
  button2Text,
  button3Icon,
  button3Text,
  button4Icon,
  button4Text,
  button5Icon,
  button5Text,
  currentId,
  setButtonClicked,
  buttonClicked,
  onAddClick,
  number,
  onModifyClick,
  handleRegister,
  setCurrentAction,
  popupContextKeyType,
  fleteName,
  fatureName,
  fromArka,
  ...fetchFunctions
}) => {
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const handleConfirm = () => {
    setHidePupUp(false);
    setCurrentAction(null);
    setButtonClicked(false);
    setDisabled(true);

    const fetchFunction = fetchMap[popupContextKey];
    if (fetchFunction) fetchFunctions[fetchFunction](number);
  };

  const handleDeleteClick = () => {
    if (currentId) {
      setShowConfirmationPopup(true);
    } else {
      alert("Nuk ekziston si faturë!");
    }
  };

  const deleteFature = async (Id) => {
    try {
      const deleteEndpoint = deleteMap[popupContextKey];
      if (deleteEndpoint) await mainAxios.delete(`${deleteEndpoint}${Id}`);

      const updatedResponseData = responseData.filter((item) => item.Id !== Id);
      setResponseData(updatedResponseData);
      if (lastObject?.Id === Id) setLastObject(null);
      if (index >= responseData.findIndex((item) => item.Id === Id)) {
        setIndex(index - 1);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const confirmDelete = async () => {
    await deleteFature(currentId);
    setShowConfirmationPopup(false);

    const fetchFunction = fetchMap[popupContextKey];
    if (fetchFunction) fetchFunctions[fetchFunction](1);
  };

  return (
    <ButtonGroup className="mb-2" style={{ width: "100%" }} disabled={fromArka}>
      {hidePupUp && (
        <PopUpConfirm
          onConfirm={handleConfirm}
          onCancel={() => setHidePupUp(false)}
          contextKey={popupContextKey}
        />
      )}
      {showConfirmationPopup && (
        <DeleteConfirmationPopup
          currentId={currentId}
          onConfirm={confirmDelete}
          onCancel={() => setShowConfirmationPopup(false)}
          setDisabled={setDisabled}
          defaultState={defaultState}
          setState={setState}
          setButtonClicked={setButtonClicked}
          fatureName={fatureName}
          fleteName={fleteName}
          state={state}
        />
      )}
      <Button
        className="button_tab"
        onClick={() => {
          setButtonClicked(true);
          setDisabled(false);
          setRows([]);
          setState(defaultState);
          onAddClick();
        }}
        disabled={buttonClicked}
      >
        {button1Icon} {button1Text}
      </Button>
      <Button
        className="button_tab"
        onClick={() => {
          onModifyClick();
          setButtonClicked(true);
          setDisabled(false);
        }}
        disabled={buttonClicked || fatureDisabled}
      >
        {button2Icon} {button2Text}
      </Button>
      <Button
        className="button_tab"
        onClick={handleDeleteClick}
        disabled={
          buttonClicked || fatureDisabled || state.fisIsCorrectiveInvoice === 1
        }
      >
        {button3Icon} {button3Text}
      </Button>
      <Button className="button_tab" onClick={anulloFature} disabled={disabled}>
        {button4Icon} {button4Text}
      </Button>
      <Button
        className="button_tab"
        onClick={handleRegister}
        disabled={disabled}
        type="submit"
      >
        {button5Icon} {button5Text}
      </Button>
    </ButtonGroup>
  );
};

export default PaneliTabeve;
